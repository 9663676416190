import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';
import { debounce } from 'lodash';

import CompleteBadge from '../../components/common/completeBadge/CompleteBadge';
import TableInfoModal from '../../components/common/tableInfo/TableInfoModal';
import TableHeaderSortIcons from '../../components/common/tables/TableHeaderSortIcons';
import CompetitorActionsDropdown from '../../components/pages/contentOptimizer/contentPage/content/competitorsTab/content/CompetitorActionsDropdown';
import DomainRankBadge from '../../components/pages/contentOptimizer/contentPage/content/competitorsTab/content/DomainRankBadge';
// import ThreeDotsCompetitorActionsDropdown from '../../components/pages/contentOptimizer/contentPage/content/competitorsTab/content/ThreeDotsCompetitorActionsDropdown';
import { competitorsSortKeys } from '../../components/pages/contentOptimizer/contentPage/content/competitorsTab/content/competitorsContentConstants';
import { updateCompetitorsSortKey } from '../../components/pages/contentOptimizer/contentPage/store/contentOptimizerContent.actions';
import {
    selectCompetitorsSortDescending,
    selectCompetitorsSortKey,
    selectCompetitorsToUpdate,
} from '../../components/pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';
import { selectContentOptimizerViewOnlyMode } from '../../components/pages/contentOptimizer/viewOnly/store/viewOnlySettings.selectors';
import {
    selectDarkMode,
    selectSwitchersVisibilitySettings,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import { difficultyBadgeSize } from '../../constants/difficultyBadgeSize';
import { domainRankCategories } from '../../constants/domainRankCategories';
import { rankabilityScoreCategories } from '../../constants/rankabilityScoreCategories';
import { sortOrders } from '../../constants/sortOrders';

import { ContentOptimizerContext } from '../../components/hocs/ContentOptimizerProvider';

import defaultFavicon from '../../resources/images/default_favicon.png';

import { getFaviconUrl } from '../helpers/getFaviconUrl';
import { getNumberWithCommaSeparator } from '../helpers/getNumberWithCommaSeparator';
import { getThemeColorClass } from '../helpers/themeHelpers';

import useIncludeInOptimalRangeChange from './useIncludeInOptimalRangeChange';

const { Link } = Typography;

const classes = {
    tableHeaderTitle:
        'd-flex align-items-center competitors-table-header-title cursor-pointer space-no-wrap',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    competitorNameWrapper: 'competitor-name-wrapper',
    competitorNameEllipsis: 'competitor-name-ellipsis',
    competitorLinkEllipsis: 'competitor-link-ellipsis',
    siteIcon: 'site-icon',
    siteLink: 'site-link',
    active: 'active',
    textDotted: 'text-decoration-dotted',
    competitorColumnLinkWrapper: 'competitor-column-link-wrapper',
};

export const useCompetitorsTableColumns = () => {
    const dispatch = useDispatch();
    const contentOptimizerId = useContext(ContentOptimizerContext);

    const darkMode = useSelector(selectDarkMode);
    const competitorsSortKey = useSelector(selectCompetitorsSortKey);
    const competitorsSortDescending = useSelector(selectCompetitorsSortDescending);
    const competitorsToUpdate = useSelector(selectCompetitorsToUpdate);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const isSwitchersVisible = false; //Uncomment when switchers are needed: useSelector(selectSwitchersVisibilitySettings(contentOptimizerId));

    const handleSorting = (sortKey) => () => {
        dispatch(updateCompetitorsSortKey(sortKey));
    };

    const fireIncludeInOptimalRangeChange = useIncludeInOptimalRangeChange();

    const sendIncludeInOptimalRangeRequest = useCallback(
        debounce((data) => {
            fireIncludeInOptimalRangeChange(data);
        }, 1000),
        []
    );

    useEffect(() => {
        if (competitorsToUpdate.length) {
            const data = [...competitorsToUpdate];

            sendIncludeInOptimalRangeRequest(data);
        }
    }, [competitorsToUpdate]);

    return [
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${getThemeColorClass(darkMode)} ${
                        competitorsSortKey === competitorsSortKeys.desktop ? classes.active : ''
                    }`}
                    onClick={handleSorting(competitorsSortKeys.desktop)}
                >
                    <Tooltip
                        title={'Desktop ranking on Google.'}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <div className={classes.textDotted}>DESKTOP</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.desktop}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'desktop',
            render: (_, record) => {
                return <div style={{ minWidth: '60px' }}>{record?.desktop || '-'}</div>;
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${getThemeColorClass(darkMode)} ${
                        competitorsSortKey === competitorsSortKeys.mobile && classes.active
                    }`}
                    onClick={handleSorting(competitorsSortKeys.mobile)}
                >
                    <Tooltip
                        title={'Mobile ranking on Google.'}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <div className={classes.textDotted}>MOBILE</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.mobile}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'mobile',
            render: (_, record) => {
                return <div style={{ minWidth: '60px' }}>{record?.mobile || '-'}</div>;
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle}  ${
                        competitorsSortKey === competitorsSortKeys.title && classes.active
                    }`}
                    onClick={handleSorting(competitorsSortKeys.title)}
                >
                    <div>COMPETITOR</div>

                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.title}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'competitor',
            render: (_, record) => {
                return (
                    <div style={{ minWidth: '200px' }}>
                        <div className={classes.tableHeaderTitle}>
                            <img
                                alt={record?.title}
                                src={record?.favicon || getFaviconUrl(record?.url)}
                                onError={(e) => {
                                    e.target.src = defaultFavicon;
                                }}
                                width={18}
                                height={18}
                                className={classes.siteIcon}
                            />
                            <div className={classes.competitorNameWrapper}>
                                <div
                                    className={`${classes.competitorNameEllipsis} ${
                                        !record?.wordCount || !record?.rankabilityScore
                                            ? 'missing-info'
                                            : ''
                                    }`}
                                >
                                    <span>{record?.title}</span>
                                </div>
                            </div>
                        </div>

                        <div className={classes.tableHeaderTitle}>
                            <div className={classes.competitorNameWrapper}>
                                <div
                                    className={`${classes.competitorNameEllipsis} ${classes.competitorLinkEllipsis}`}
                                >
                                    <Link
                                        href={record?.url}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={classes.siteLink}
                                    >
                                        {record?.url}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${getThemeColorClass(darkMode)} ${
                        competitorsSortKey === competitorsSortKeys.wordCount && classes.active
                    }`}
                    onClick={handleSorting(competitorsSortKeys.wordCount)}
                >
                    <div>WORD COUNT</div>

                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.wordCount}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'wordCount',
            render: (_, record) => {
                return (
                    <div style={{ minWidth: '100px' }}>
                        {record?.rankabilityScore && record?.wordCount ? (
                            getNumberWithCommaSeparator(record?.wordCount)
                        ) : (
                            <Tooltip
                                title='Unfortunately, some URLs are inaccessible due to technical reasons or they block our ability to scrape and import their content for analysis.'
                                overlayClassName={`${classes.tooltipOverlay}
                                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
                            >
                                <span className={classes.textDotted}>n/a</span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${getThemeColorClass(darkMode)} ${
                        competitorsSortKey === competitorsSortKeys.rankabilityScore &&
                        classes.active
                    }`}
                    onClick={handleSorting(competitorsSortKeys.rankabilityScore)}
                >
                    <Tooltip
                        title={
                            'Measures content quality and relevance using natural language processing (NLP) AI technology from Google and IBM Watson.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <div className={classes.textDotted}>RANKABILITY SCORE</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Rankability Score'}
                        infoCategories={rankabilityScoreCategories}
                    />
                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.rankabilityScore}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'rankabilityScore',
            render: (_, record) => {
                return (
                    <div style={{ minWidth: '138px' }}>
                        <CompleteBadge
                            size={difficultyBadgeSize.small}
                            progress={record?.rankabilityScore}
                        />
                    </div>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${getThemeColorClass(darkMode)} ${
                        competitorsSortKey === competitorsSortKeys.domainRank && classes.active
                    }`}
                    onClick={handleSorting(competitorsSortKeys.domainRank)}
                >
                    <Tooltip
                        title={
                            'Measures the strength of a domain’s backlink profile on a 1000-point logarithmic scale, using Google’s original PageRank formula.'
                        }
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${darkMode ? classes.tooltipOverlayDark : ''}`}
                    >
                        <div className={classes.textDotted}>DOMAIN SCORE</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Domain Backlink Score'}
                        infoCategories={domainRankCategories}
                    />
                    <TableHeaderSortIcons
                        active={competitorsSortKey === competitorsSortKeys.domainRank}
                        order={competitorsSortDescending ? sortOrders.asc : sortOrders.desc}
                    />
                </div>
            ),
            dataIndex: 'domainRank',
            render: (_, record) => {
                return (
                    <div style={{ minWidth: '110px' }}>
                        <DomainRankBadge progress={record?.domainRank} />
                    </div>
                );
            },
        },
        !viewOnlyMode && {
            // NOTE: Uncomment when it's needed
            // title: <ThreeDotsCompetitorActionsDropdown />,
            title: null,
            dataIndex: 'actions',
            className: 'competitor-list-actions',
            width: '4%',
            align: 'center',
            render: (_, record) => (
                <CompetitorActionsDropdown
                    record={record}
                    isSwitchersVisible={isSwitchersVisible}
                />
            ),
        },
    ].filter(Boolean);
};
