import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, Empty, Spin, Typography } from 'antd';

import { contentBriefPathes } from '../../../../../../../../../constants/queryPathes';
import {
    findTags,
    isEndOfSentencePattern,
    urlSomewhereInTheText,
} from '../../../../../../../../../constants/regularExpression';

import { ContentOptimizerContext } from '../../../../../../../../hocs/ContentOptimizerProvider';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../../../../../utils/axiosAPI';
import { compareRanks } from '../../../../../../../../../utils/helpers/compareRanks';
import { getAxiosHeaders } from '../../../../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../../../../utils/helpers/idGenerator';
import { openBasicErrorNotification } from '../../../../../../../../../utils/helpers/openBasicErrorNotification';
import { getThemeColorClass } from '../../../../../../../../../utils/helpers/themeHelpers';

import Loader from '../../../../../../../../common/loader/Loader';
import RankTitle from '../../../../briefTab/content/tabsContent/outline/ranks/rankItem/RankTitle';

const { Link } = Typography;

const classes = {
    examplesModalContentWrapper: 'examples-modal-content-wrapper',
    rankItem: 'rank-item',
    exampleSentence: 'example-sentence',
    examplesHighlitedKeyword: 'examples-highlited-keyword',
    spinSkeleton: 'examples-spin-skeleton',
    noDataWrapper: 'no-data-wrapper',
    link: 'link-hover-highlight show-more-link',
    tag: 'example-tag',
};

const ExamplesModalContent = ({ keywordItem }) => {
    const initalNumberOfVisibleItems = 3;

    const contentOptimizerId = useContext(ContentOptimizerContext);

    const [examplesData, setExamplesData] = useState([]);
    const [examplesCount, setExamplesCount] = useState([]);

    const darkMode = useSelector(selectDarkMode);

    useEffect(() => {
        if (contentOptimizerId) {
            axiosAPI
                .get(`${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}`, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    const mappedExamplesData = result?.data?.task_result
                        ? JSON.parse(result.data.task_result)
                              ?.sort((a, b) => compareRanks(a, b))
                              .map((example) => {
                                  const filteredSentences = getFilteredSentences(
                                      example,
                                      keywordItem
                                  );

                                  const result = {
                                      filteredSentences: filteredSentences,
                                      ...example,
                                  };

                                  return result;
                              })
                        : [];
                    setExamplesData(mappedExamplesData);
                })
                .catch((error) => {
                    openBasicErrorNotification();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentOptimizerId]);

    const reArrangeRows = (splitedParagraph = []) => {
        const isContinueSentencePattern = /(^[ \s]+[a-z]+)|^[a-z&-,:+']+\s*/;

        const rearrangedRows = [];

        for (let i = 0; i < splitedParagraph.length; i++) {
            const currentRow = splitedParagraph[i];
            const lastChar = currentRow.substr(currentRow.length - 1);
            const rowEndMatches = lastChar.match(isEndOfSentencePattern);

            if (i === 0 || !rowEndMatches || rowEndMatches.length < 1) {
                let combinedRow = currentRow;

                // Check if the current row and the next row start with ',' or a lowercase word
                while (
                    i + 1 < splitedParagraph.length &&
                    !combinedRow.substr(combinedRow.length - 1).match(isEndOfSentencePattern) &&
                    isContinueSentencePattern.test(splitedParagraph[i + 1])
                ) {
                    const separator =
                        splitedParagraph[i + 1].startsWith(',') ||
                        splitedParagraph[i + 1].startsWith(':')
                            ? ''
                            : ' ';
                    combinedRow += separator + splitedParagraph[i + 1];
                    i++;
                }

                rearrangedRows.push(combinedRow);
            } else {
                rearrangedRows.push(currentRow);
            }
        }

        return rearrangedRows;
    };

    const findKeywordMatchesInSentence = (keywordItem, sentence = '') => {
        let matchedSentence = null;

        if (!keywordItem || !sentence) {
            return matchedSentence;
        }

        const lowerCasedSentence = sentence.toLowerCase();
        const lowerCasedKeyword = keywordItem.keyword?.toLowerCase();

        /** There is try-catch block to fix broken regex expression
         * Important to search by regex first, then by keyword spelling
         */
        try {
            matchedSentence = lowerCasedSentence.match(keywordItem.regex);

            if (!matchedSentence) {
                matchedSentence = lowerCasedSentence.match(lowerCasedKeyword);
            }
        } catch (_err) {
            matchedSentence = lowerCasedSentence.match(lowerCasedKeyword);
        }

        return matchedSentence;
    };

    const getWrappingTag = (dom, sentence) => {
        let foundTag = '';
        const blockTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];

        blockTags.forEach((tag) => {
            if (foundTag) {
                return;
            }

            for (let element of dom.getElementsByTagName(tag)) {
                const match = element.innerText
                    .trim()
                    // without dot or symbol that means end of sentence
                    .includes(sentence.substr(0, sentence.length - 1));
                if (match) {
                    foundTag = tag;
                    break;
                }
            }
        });

        return foundTag;
    };

    const insertNewlineAfterBlocks = (element) => {
        const blockTags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'section', 'article'];
        blockTags.forEach((tag) => {
            element.querySelectorAll(tag).forEach((el) => {
                el.insertAdjacentText('afterend', '\n');
            });
        });
    };

    const processHtml = (str) => {
        // remove all urls inside
        for (const match of str.matchAll(urlSomewhereInTheText)) {
            str = str.replace(match[0], '');
        }

        return str.replaceAll('>', '> '); // Add spaces between tags to not join words when get inner text
    };

    const getFilteredSentences = (rankItem, keywordItem) => {
        if (rankItem?.content && (keywordItem?.regex || keywordItem?.keyword)) {
            const maxSentenceWordsCount = 250;
            let filteredSentences = [];
            let dividedSentences = [];

            const dom = new DOMParser().parseFromString(processHtml(rankItem.content), 'text/html');

            insertNewlineAfterBlocks(dom);

            const content = rankItem?.isHtml
                ? // remove tags if textContent still returns text with tags
                  dom.getElementsByTagName('body')[0]?.textContent?.trim().replace(findTags, '')
                : rankItem.content.toString();

            let splitedMarkupLines = content.split('\n') || [];
            splitedMarkupLines = splitedMarkupLines.map((x) => x?.trim()).filter((x) => x?.trim()); // get only items that contain some text

            const reArrangedRows = reArrangeRows(splitedMarkupLines);

            reArrangedRows.forEach((inputString) => {
                const splitSentences = inputString.split(isEndOfSentencePattern);
                const lastChar = inputString.substr(inputString.length - 1);
                const rowEndMatches = lastChar.match(isEndOfSentencePattern);
                const endOfSentence = rowEndMatches ? lastChar : '.';

                dividedSentences = dividedSentences.concat(
                    splitSentences
                        .filter((sentence) => {
                            return sentence.trim() !== '' && sentence.split(' ').length > 1; // get only sentences that contains more than 1 word
                        })
                        .map((x) => x.trim() + endOfSentence)
                );
            });

            // For duplicates filtering
            const textSentences = Array.from(new Set(dividedSentences));

            textSentences?.forEach((sentence) => {
                const matchedSentence = findKeywordMatchesInSentence(keywordItem, sentence);

                if (
                    matchedSentence &&
                    !(matchedSentence.index === 0 && matchedSentence[0] === matchedSentence.input)
                ) {
                    const words = sentence?.split(' ') || [];
                    const tag = getWrappingTag(dom, sentence);

                    if (words.length <= maxSentenceWordsCount) {
                        filteredSentences.push({
                            startIndex: matchedSentence.index,
                            keywordLength: matchedSentence[0]?.length,
                            sentence: sentence.replace(/\.{2,}/g, (match) =>
                                match === '..' ? '.' : match
                            ),
                            tag,
                        });
                    } else {
                        const keywordIndex = words.indexOf(matchedSentence[0]);

                        const startIndex = Math.max(0, keywordIndex - 17);
                        const endIndex = Math.min(words.length, keywordIndex + 18);

                        let truncatedText = words.slice(startIndex, endIndex).join(' ');

                        if (startIndex > 0) {
                            truncatedText = '...' + truncatedText;
                        }
                        if (endIndex < words.length) {
                            truncatedText += '...';
                        }

                        const truncatedMatchSentence = findKeywordMatchesInSentence(
                            keywordItem,
                            truncatedText
                        );

                        if (truncatedMatchSentence) {
                            filteredSentences.push({
                                startIndex: truncatedMatchSentence.index,
                                keywordLength: truncatedMatchSentence[0]?.length,
                                sentence: truncatedText.replace(/\.{2,}/g, (match) =>
                                    match === '..' ? '.' : match
                                ),
                                tag,
                            });
                        }
                    }
                }
            });

            return filteredSentences;
        }

        return [];
    };

    const getHighlightSecondaryKeywords = (sentence) => {
        const firstPart = sentence?.sentence.substring(0, sentence?.startIndex);
        const keywordPart = sentence?.sentence.substring(
            sentence?.startIndex,
            sentence?.startIndex + sentence?.keywordLength
        );

        const secondPart = sentence?.sentence.substring(
            sentence?.startIndex + sentence?.keywordLength,
            sentence?.sentence?.length
        );

        const matchedSecondPart = findKeywordMatchesInSentence(keywordItem, secondPart);

        if (matchedSecondPart) {
            const secondPartOfSentence = {
                startIndex: matchedSecondPart?.index,
                keywordLength: matchedSecondPart[0]?.length,
                sentence: secondPart.replace(/\.{2,}/g, (match) => (match === '..' ? '.' : match)),
            };

            return (
                <>
                    {firstPart}
                    <span
                        className={`${classes.examplesHighlitedKeyword} ${getThemeColorClass(
                            darkMode
                        )}`}
                    >
                        {keywordPart}
                    </span>
                    {getHighlightSecondaryKeywords(secondPartOfSentence)}
                </>
            );
        } else {
            return (
                <>
                    {firstPart}
                    <span
                        className={`${classes.examplesHighlitedKeyword} ${getThemeColorClass(
                            darkMode
                        )}`}
                    >
                        {keywordPart}
                    </span>
                    {secondPart}
                </>
            );
        }
    };

    const handleShowMore = (index) => {
        const newCount = examplesCount[index]
            ? examplesCount[index] + initalNumberOfVisibleItems
            : initalNumberOfVisibleItems * 2;

        setExamplesCount({
            ...examplesCount,
            [index]: newCount,
        });
    };

    const data = examplesData?.filter((rank) => rank?.filteredSentences?.length > 0 && rank?.title);

    return (
        <Spin
            spinning={!examplesData?.length}
            indicator={<Loader />}
            className={classes.spinSkeleton}
        >
            {data?.length > 0 ? (
                <div className={classes.examplesModalContentWrapper}>
                    {data?.map((rank, index) => {
                        return (
                            <Card
                                key={index}
                                title={<RankTitle rank={rank} />}
                                bordered
                                className={classes.rankItem}
                            >
                                {rank?.filteredSentences
                                    ?.sort((a, b) => b.tag.localeCompare(a.tag))
                                    ?.slice(0, examplesCount[index] || initalNumberOfVisibleItems)
                                    ?.map((sentence) => {
                                        return (
                                            <div className='d-flex'>
                                                {/* Uncomment when tags are needed 
                                                <div className={classes.tag}>{sentence.tag}</div> */}
                                                <div
                                                    key={getRandomId()}
                                                    className={`${
                                                        classes.exampleSentence
                                                    } ${getThemeColorClass(darkMode)}`}
                                                >
                                                    {getHighlightSecondaryKeywords(sentence)}
                                                </div>
                                            </div>
                                        );
                                    })}

                                {rank?.filteredSentences?.length >
                                    (examplesCount[index] || initalNumberOfVisibleItems) && (
                                    <Link
                                        className={`${classes.link} ${getThemeColorClass(
                                            darkMode
                                        )}`}
                                        underline
                                        onClick={() => handleShowMore(index)}
                                    >
                                        Show more
                                    </Link>
                                )}
                            </Card>
                        );
                    })}
                </div>
            ) : (
                <div className={classes.noDataWrapper}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
        </Spin>
    );
};

export default ExamplesModalContent;
