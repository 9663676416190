import { batch, useDispatch } from 'react-redux';

import { contentBriefPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

import { axiosAPI } from '../axiosAPI';

import {
    setBriefCitations,
    setBriefQuestions,
    setBriefTimings,
    setCurrentBriefId,
    setRanksHtags,
} from '../../components/pages/contentOptimizer/contentPage/content/briefTab/content/tabsContent/store/contentBriefsOutline.actions';
import { compareRanks } from '../helpers/compareRanks';
import { getAxiosHeaders } from '../helpers/getAxiosHeaders';
import { getRanksWithCorrectSortMobilePosition } from '../helpers/getRanksWithCorrectSortMobilePosition';
import { openBasicErrorNotification } from '../helpers/openBasicErrorNotification';

const useGetListOfContentBriefs = () => {
    const dispatch = useDispatch();

    const getListOfContentBriefs = (contentOptimizerId) => {
        return axiosAPI
            .get(
                `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}`,
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (
                    result?.status === statusCodes.success &&
                    result?.data?.task_result?.length > 0
                ) {
                    const sortedRanks = getRanksWithCorrectSortMobilePosition(
                        JSON.parse(result?.data?.task_result)
                            .filter((item) => item?.htags?.length > 0)
                            .sort((a, b) => compareRanks(a, b))
                    );
                    batch(() => {
                        dispatch(setRanksHtags(sortedRanks));
                        dispatch(setBriefQuestions(result?.data?.keyword_suggestions));
                        dispatch(setBriefTimings(result?.data?.queue_timing));
                        dispatch(setBriefCitations(result?.data?.common_citations));
                        dispatch(setCurrentBriefId(result?.data?.id));
                    });
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    return getListOfContentBriefs;
};

export default useGetListOfContentBriefs;
