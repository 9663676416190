import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Input, Popconfirm, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import EditIcon from '../../../resources/icons/EditIcon';
import RemoveIcon from '../../../resources/icons/outline/RemoveIcon';

import { getDeletePopconfirmClass } from '../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../utils/hooks/useIconColor';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    editAndDeleteButtonsWrapper: 'edit-n-delete-buttons-wrapper d-flex',
    linkInputWrapper: 'edit-buttons-wrapper d-flex',
    iconWrapper: 'edit-buttons-icon-wrapper',
    focusedWrapper: 'focused-button-wrapper',
    errorMessageText: 'ant-form-item-explain-error error-message-text',
};

const EditAndDeleteButtons = ({
    value = '',
    onConfirmEdit = () => {},
    onConfirmDelete = () => {},
}) => {
    const valueInputRef = useRef(null);

    const darkMode = useSelector(selectDarkMode);
    const iconColor = useIconColor();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isEditFieldError, setIsEditFieldError] = useState(false);
    const [areButtonsFocused, setButtonsFocused] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const resetErrors = () => {
        setInputError('');
        setIsEditFieldError(false);
    };

    const handleEditValue = () => {
        const newValue = valueInputRef.current?.input?.value?.trim();
        resetErrors();

        if (newValue) {
            const result = onConfirmEdit(newValue);

            if (result?.error) {
                setInputError(result?.error);
                setIsEditFieldError(true);

                return;
            }

            setInputValue(newValue);
            setIsEditMode(false);
        } else {
            setIsEditFieldError(true);
        }
    };

    const isInputError = !!inputError?.length;

    const handleOpenChange = (newOpen) => {
        setButtonsFocused(newOpen);
    };

    return (
        <div className={classes.editAndDeleteButtonsWrapper}>
            {isEditMode ? (
                <div>
                    <Input
                        ref={valueInputRef}
                        placeholder='The value is required'
                        defaultValue={inputValue}
                        onChange={resetErrors}
                        onPressEnter={handleEditValue}
                        status={isInputError || isEditFieldError ? 'error' : undefined}
                        required
                        autoFocus
                    />
                    {isInputError ? (
                        <Typography className={classes.errorMessageText}>{inputError}</Typography>
                    ) : null}
                </div>
            ) : (
                <span>{inputValue}</span>
            )}
            <div
                className={`${classes.iconWrapper} ${
                    areButtonsFocused ? classes.focusedWrapper : ''
                }`}
            >
                {isEditMode ? (
                    <div onClick={handleEditValue}>
                        <CheckOutlined color={iconColor} />
                    </div>
                ) : (
                    <div onClick={() => setIsEditMode((prev) => !prev)}>
                        <EditIcon color={iconColor} />
                    </div>
                )}
                <Popconfirm
                    title='Are you sure you want to delete this project?'
                    onConfirm={onConfirmDelete}
                    okText='Yes'
                    cancelText='Cancel'
                    overlayClassName={`${getDeletePopconfirmClass(darkMode)}`}
                    onOpenChange={handleOpenChange}
                >
                    <div>
                        <RemoveIcon color={iconColor} />
                    </div>
                </Popconfirm>
            </div>
        </div>
    );
};

export default EditAndDeleteButtons;
