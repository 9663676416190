import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';

import MoreHorizontalIcon from '../../../../../resources/icons/MoreHorizontalIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { getInitialStripeUrl } from '../../../../../utils/helpers/getInitialStripeUrl';
import { openBasicErrorNotification } from '../../../../../utils/helpers/openBasicErrorNotification';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import CustomDropdown from '../../../../common/dropdown/CustomDropdown';

import { statusCodes } from '../../../../../constants/statusCodes';

import AdminActionsService from '../../../../../services/admin.service';
import { updateOrganizationSettings } from '../store/adminOrganizationsSettings.actions';

const classes = {
    actionsButton: 'actions-button d-flex flex-center align-items-center btn-hover-light-primary',
    dropdownOverlay: 'dropdown-menu dropdown-with-arrow-menu-overlay',
};

const OrganizationActionsDropdown = ({ organization }) => {
    const { isOpen, handleOpen } = useOpen();
    const dispatch = useDispatch();

    const redirectToStripe = (url) => {
        window.open(`${getInitialStripeUrl()}${url}`, '_blank');
    };

    const handleChangeOrganizationSettings = (settings) => {
        if (!organization?.id || !settings) return;

        AdminActionsService.updateOrganizationSettings(organization.id, settings)
            .then(({ data, status }) => {
                if (status === statusCodes.success && data?.settings) {
                    dispatch(
                        updateOrganizationSettings({
                            id: organization.id,
                            settings: data.settings,
                        })
                    );
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    const organizationsMenuItems = [
        {
            label: 'View Stripe Customer',
            key: 'viewStripeCustomer',
            onClick: () => {
                redirectToStripe(`/customers/${organization?.stripe_customer_id}`);
            },
        },
        {
            label: 'View Stripe Subscription',
            key: 'viewStripeSubscription',
            onClick: () => {
                redirectToStripe(`/subscriptions/${organization?.stripe_subscription_id}`);
            },
        },
        {
            label: `${organization?.settings?.allow_nlp_export ? 'Deny' : 'Allow'} NLP Export`,
            key: 'manageNlpExport',
            onClick: () => {
                handleChangeOrganizationSettings({
                    allow_nlp_export: !organization?.settings?.allow_nlp_export,
                });
            },
        },
    ];

    return (
        <CustomDropdown
            withArrow
            dropdownOpenElement={
                <Button className={classes.actionsButton}>
                    <MoreHorizontalIcon color={colorPalette.colorPrimary} />
                </Button>
            }
            dropdownMenu={{
                items: organizationsMenuItems,
                selectable: false,
                defaultSelectedKeys: [],
            }}
            dropdownTriggersArray={['click']}
            dropdownOverlayClassName={classes.dropdownOverlay}
            isDropdownOpen={isOpen}
            handleOpenChange={handleOpen}
        />
    );
};

export default OrganizationActionsDropdown;
