import React from 'react';

import { useOpen } from '../../../../../utils/hooks/useOpen';

import PlusIcon from '../../../../../resources/icons/Plus';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import EditQuotaModal from './EditQuotaModal';

const classes = {
    addCreditsIconContainer: 'add-credits-icon-container',
};

const EditQuotaModalWrapper = ({ editableQuota, quotaItem }) => {
    const { isOpen, handleOpen } = useOpen();

    return (
        <>
            <div className={classes.addCreditsIconContainer} onClick={handleOpen}>
                <PlusIcon color={colorPalette.colorPrimary} size={15} />
            </div>
            <EditQuotaModal
                editableQuota={editableQuota}
                quotaItem={quotaItem}
                isOpen={isOpen}
                handleOpen={handleOpen}
            />
        </>
    );
};

export default EditQuotaModalWrapper;
