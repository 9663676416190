import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Tag, Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { getNumberWithCommaSeparator } from '../../../../utils/helpers/getNumberWithCommaSeparator';

import MoreHorizontalIcon from '../../../../resources/icons/MoreHorizontalIcon';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import CancelCreationButton from '../../../common/buttons/CancelCreationButton';
import CompleteBadge from '../../../common/completeBadge/CompleteBadge';
import CreateInfoSection from '../../../common/mainPagesLists/CreateInfoSection';
import FlagCountrySection from '../../../common/mainPagesLists/FlagCountrySection';
import RankabilityLoader from '../../../common/rankabilityLoader/RankabilityLoader';

import ContentOptimizerShareDropdown from '../mainPage/content/ContentOptimizerShareDropdown';

import { updateContentOptimizerShared } from '../mainPage/store/contentOptimizerMain.actions';

const { Link, Title } = Typography;

const classes = {
    cardWrapper: 'd-flex card-wrapper w-100',
    listCardWrapper: 'd-flex list-card-wrapper list-left-block',
    listCardHeader: 'd-flex card-header',
    listKeywordWrapper: 'd-flex w-100 align-items-center',
    listRightBlock: 'list-right-block d-flex align-items-center',
    listCompleteInfoBlock:
        'list-complete-info d-flex align-items-center justify-content-between w-100',
    listLinkWrapper: 'list-link-wrapper',
    listLink: 'list-link',
    itemNotCompleted: 'item-not-completed',
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',
    listWordCount: 'list-word-count space-no-wrap',
    breakRow: 'flex-break-row',
    projectsListWrapper: 'd-flex w-100',
    projectsWrapper: 'projects-wrapper d-flex align-items-center',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    emptySpace: 'empty-space',
    rankabilityLoaderWrapper: 'rankability-loader-wrapper',
};

const OptimizerKanbanView = ({
    optimizerItem,
    handleContentOptimizerNavigate,
    isOptimizerItemCompleted,
    users,
    showEmptySpace,
    startedStatus,
    renderCancelButton,
    cancelItem,
    disabled,
}) => {
    const dispatch = useDispatch();
    const darkMode = useSelector(selectDarkMode);

    const preventRedirect = (e) => {
        e?.preventDefault();

        if (handleContentOptimizerNavigate) {
            handleContentOptimizerNavigate(e);
        }
    };

    const manageContextMenuHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    return (
        <div className={classes.cardWrapper}>
            <div className={classes.listCardWrapper}>
                <div className={classes.listCardHeader}>
                    <div className={classes.listKeywordWrapper}>
                        <Checkbox value={optimizerItem.id} />
                        <div className={classes.listLinkWrapper}>
                            <Link
                                className={`${classes.listLink} ${
                                    !isOptimizerItemCompleted && classes.itemNotCompleted
                                } ${darkMode ? 'dark-mode' : ''}`}
                                href={
                                    isOptimizerItemCompleted
                                        ? `optimizer/${optimizerItem?.id}`
                                        : 'optimizer'
                                }
                                onClick={preventRedirect}
                                onContextMenu={
                                    !isOptimizerItemCompleted || disabled
                                        ? manageContextMenuHandler
                                        : undefined
                                }
                            >
                                {optimizerItem.content_brief.keywords}
                            </Link>
                            <FlagCountrySection
                                countryCode={optimizerItem?.content_brief?.country_code}
                                languageCode={optimizerItem?.content_brief?.language_code}
                            />
                            <div className={classes.breakRow}></div>
                            <div className={classes.projectsListWrapper}></div>
                        </div>
                    </div>
                    {!disabled && (
                        <ContentOptimizerShareDropdown
                            optimizerId={optimizerItem.id}
                            briefId={optimizerItem?.content_brief_id}
                            isShared={optimizerItem?.shared}
                            shareLink={optimizerItem?.share_link?.url_code}
                            onChangedSharedCallback={(checked) =>
                                dispatch(
                                    updateContentOptimizerShared({
                                        id: optimizerItem.id,
                                        optimizer: checked,
                                    })
                                )
                            }
                        >
                            <Button
                                className={`${classes.listMoreButton} ${
                                    darkMode ? 'dark-mode' : ''
                                }`}
                            >
                                <MoreHorizontalIcon color={colorPalette.basicGray} />
                            </Button>
                        </ContentOptimizerShareDropdown>
                    )}
                </div>
                <CreateInfoSection
                    createdByUserId={optimizerItem.user_id}
                    createdAt={optimizerItem.created_at}
                    timeFromNow={!isOptimizerItemCompleted}
                />
            </div>

            <div className={classes.listRightBlock}>
                {!disabled ? (
                    isOptimizerItemCompleted ? (
                        <>
                            <div className={classes.listCompleteInfoBlock}>
                                <Title
                                    level={5}
                                    className={`${classes.listWordCount} ${
                                        darkMode ? 'dark-mode' : ''
                                    }`}
                                >
                                    {`Word Count: ${
                                        getNumberWithCommaSeparator(optimizerItem.word_count) || 0
                                    }`}
                                </Title>
                                <CompleteBadge
                                    size={'small'}
                                    progress={Math.ceil(optimizerItem.rankability_score) || 0}
                                />
                            </div>

                            {optimizerItem?.content_brief?.url && (
                                <div className='w-100'>
                                    <Tooltip
                                        overlayClassName={`${classes.tooltipOverlay}
                                        ${darkMode ? classes.tooltipOverlayDark : ''}`}
                                        title={optimizerItem?.content_brief?.url}
                                    >
                                        <Typography className='text-overflow-ellipsis'>
                                            {optimizerItem?.content_brief?.url}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            )}
                            {optimizerItem?.content_brief?.projects?.length > 0 ? (
                                <div className={classes.projectsWrapper}>
                                    {optimizerItem?.content_brief?.projects.map((project) => (
                                        <Tag color='processing' key={project.id}>
                                            {project.name}
                                        </Tag>
                                    ))}
                                </div>
                            ) : (
                                <div className={`${showEmptySpace && classes.emptySpace}`} />
                            )}
                        </>
                    ) : (
                        <div
                            className={`${classes.rankabilityLoaderWrapper}`}
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <RankabilityLoader
                                optimizerStatus={optimizerItem?.queue_status}
                                percentageStatus={optimizerItem?.queue_progress}
                                startedStatus={startedStatus}
                            />
                            {renderCancelButton && (
                                <CancelCreationButton
                                    popconfirmTitle='Are you sure you want to cancel this Optimizer?'
                                    creationDate={
                                        optimizerItem?.content_brief?.user_created_at_time ||
                                        optimizerItem?.created_at
                                    }
                                    handleCancel={cancelItem}
                                />
                            )}
                        </div>
                    )
                ) : (
                    <Typography style={{ whiteSpace: 'normal' }}>
                        Unfortunately, your Content Optimizer request has encountered an issue.
                        Please delete this Optimizer and run it again.
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default OptimizerKanbanView;
