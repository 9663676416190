import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Input, Modal, Typography } from 'antd';

import CloseIcon from '../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { parseErrorMessages } from '../../../../../utils/helpers/parseErrorMessages';

import { organizationFieldsPatterns } from '../../constants';
import { globalAdminPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

import { updateQuota } from '../store/adminQuotasSettings.actions';

const { Title } = Typography;

const classes = {
    modalWrapper: 'edit-quota-modal',
    modalWrapperDark: 'edit-quota-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const EditSubscriptionsModal = ({ editableSubscription, quotaId, isOpen, handleOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        setError(null);
        setIsLoading(false);
        form.resetFields();
    };

    const initialValues = Object.fromEntries(
        Object.keys({ ...organizationFieldsPatterns }).map((key) => [key, editableSubscription?.[key]])
    );

    const getValuesDiff = (values, initialValues) => {
        return Object.fromEntries(
            Object.entries(values).filter(([key, value]) => value !== initialValues[key])
        );
    };

    const handleEditSubscription = (values) => {
        const changedValues = getValuesDiff(values, initialValues);

        if (Object.keys(changedValues).length === 0) {
            handleOpen();

            return;
        }

        setIsLoading(true);

        axiosAPI
            .patch(
                `${globalAdminPathes.changeOrganization}/${editableSubscription?.id}`,
                { ...changedValues },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(updateQuota({ id: quotaId, ...changedValues }));
                    handleOpen();
                }
            })
            .catch((error) => {
                setError(
                    parseErrorMessages(error?.response) ||
                        'Something went wrong. Please try again later.'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Modal
                centered
                title='Edit Subscription'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper} ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleEditSubscription}
                    initialValues={initialValues}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        <Title level={5} className={classes.modalSubtitle}>
                            {editableSubscription?.name}
                        </Title>
                        {error && <ErrorMessage errorMessage={error} />}
                        {Object.keys(organizationFieldsPatterns).map((key) => (
                            <Form.Item
                                key={key}
                                name={key}
                                label={organizationFieldsPatterns[key]?.name}
                                rules={[
                                    {
                                        max: 64,
                                        required: true,
                                        pattern: organizationFieldsPatterns[key]?.pattern,
                                        message: `Please enter a correct ${organizationFieldsPatterns[key]?.name}`,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={`Enter ${organizationFieldsPatterns[key]?.name?.toLowerCase()}`}
                                />
                            </Form.Item>
                        ))}
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button type='primary' size='large' ghost onClick={handleOpen}>
                            Cancel
                        </Button>
                        <Button type='primary' size='large' htmlType='submit' loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default EditSubscriptionsModal;
