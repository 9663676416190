import { batch } from 'react-redux';

import {
    setContentOptimizerData,
    setContentOptimizerMainTotalCount,
    setContentOptimizersCount,
} from '../../components/pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';

import { QueueStatus } from '../../constants/queueConsts';

/**
 * Order by "In Progress" first, then "Queued", then others according to Sort By dropdown menu
 */
export const sortOptimizerListByStatuses = (optimizerList = [], addedElements = []) => {
    const sortedStatusList = [QueueStatus.IN_PROGRESS, QueueStatus.QUEUED, QueueStatus.NOT_STARTED];

    const optimizersInProgress = optimizerList.filter(
        (a) => a.queue_status === QueueStatus.IN_PROGRESS
    );

    const optimizersQueued = optimizerList.filter((a) =>
        [QueueStatus.QUEUED, QueueStatus.NOT_STARTED].includes(a.queue_status)
    );

    return [
        ...optimizersInProgress,
        ...optimizersQueued,
        ...addedElements,
        ...optimizerList.filter((a) => !sortedStatusList.includes(a.queue_status)),
    ];
};

export const processOptimizerListResult = (optimizerList, headers, dispatch, queryParams) => {
    batch(() => {
        dispatch(setContentOptimizerData(optimizerList));
        dispatch(
            setContentOptimizerMainTotalCount(
                (headers?.['x-total-pages'] || 0) * (queryParams?.per_page || 20)
            )
        );
        dispatch(
            setContentOptimizersCount({
                active: parseInt(headers?.['x-active-count']) || 0,
                scored: parseInt(headers?.['x-scored-count']) || 0,
                archived: parseInt(headers?.['x-archived-count']) || 0,
                projects: parseInt(headers?.['x-projects-count']) || 0,
            })
        );
    });
};
