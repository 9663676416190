import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Dropdown, Popover, Tooltip } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import LinkIcon from '../../../../../resources/icons/editor/LinkIcon';
import OrderedListIcon from '../../../../../resources/icons/editor/OrderedListIcon';
import TextAlignCenterIcon from '../../../../../resources/icons/editor/TextAlignCenterIcon';
import TextAlignJustifyCenterIcon from '../../../../../resources/icons/editor/TextAlignJustifyCenterIcon';
import TextAlignLeftIcon from '../../../../../resources/icons/editor/TextAlignLeftIcon';
import TextAlignRightIcon from '../../../../../resources/icons/editor/TextAlignRightIcon';
import UnorderedListIcon from '../../../../../resources/icons/editor/UnorderedListIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import KeyPressListener from '../../../keyPressListener/KeyPressListener';
import { keyActionTypes } from '../../../keyPressListener/constants';

import {
    getDropdownOverlayClass,
    getThemeColorClass,
    getTooltipOverlayClass,
} from '../../../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { alignments } from '../../constants/alignments';
import { EDITOR_TOOLBAR_ID } from '../EditorToolbar';
import { getIconColorByDarkMode } from '../constants';

import CustomButtonsTooltip from './CustomButtonsTooltip';
import AddLinkContent from './content/AddLinkContent';

const classes = {
    listButtonsSection: 'list-buttons-section',
    alignButton: 'align-button d-flex justify-content-between align-items-center',
    arrowIcon: 'arrow-icon',
    tooltipOverlay: 'toolbar-tooltip-overlay',

    alignDropdownOverlay: 'toolbar-dropdown-overlay align-dropdown-overlay',
    linksDropdown: 'links-dropdown',
    linksDropdownOverlay: 'popover-overlay links-popover-overlay',
    textAlignDropdown: 'text-align-dropdown',
    editorTextAlignDropdown: 'editor-text-align-dropdown',
    linksDropdownOverlayDark: 'popover-overlay-dark',
};

const ListButtonsSection = () => {
    const { editor } = useCurrentEditor();

    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();

    const { isOpen: isAddLinkOpen, handleOpen: handleAddLinkOpen } = useOpen();

    const darkMode = useSelector(selectDarkMode);

    const justifyOptions = [
        {
            key: alignments.left.key,
            optionLabel: <TextAlignLeftIcon color={getIconColorByDarkMode(darkMode)} />,
            label: '',
            tooltip: 'Toggle left align',
        },
        {
            key: alignments.center.key,
            optionLabel: <TextAlignCenterIcon color={getIconColorByDarkMode(darkMode)} />,
            label: '',
            tooltip: 'Toggle center align',
        },
        {
            key: alignments.right.key,
            optionLabel: <TextAlignRightIcon color={getIconColorByDarkMode(darkMode)} />,
            label: '',
            tooltip: 'Toggle right align',
        },
        {
            key: alignments.justify.key,
            optionLabel: <TextAlignJustifyCenterIcon color={getIconColorByDarkMode(darkMode)} />,
            label: '',
            tooltip: 'Toggle justify align',
        },
    ];

    const getSelectedTextAlign = (editor) => {
        if (!editor) return null;

        const findTextAlign = (node) =>
            node.attrs?.textAlign ||
            node.content?.content?.map(findTextAlign).find(Boolean) ||
            null;

        return (
            editor.state.selection.content().content?.content?.map(findTextAlign).find(Boolean) ||
            null
        );
    };

    const getActiveAlign = () => {
        return (
            justifyOptions.find((item) => editor.isActive({ textAlign: item?.key }))?.key ||
            getSelectedTextAlign(editor) ||
            justifyOptions[0]?.key
        );
    };

    const justifyOptionItems = justifyOptions.map((item) => {
        return {
            key: item.key,
            label: (
                <Tooltip
                    overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(
                        darkMode
                    )}`}
                    placement='right'
                    title={<CustomButtonsTooltip title={item.tooltip} />}
                >
                    <div>{item.optionLabel}</div>
                </Tooltip>
            ),
            onClick: () => {
                editor.chain().focus().setTextAlign(item.key).run();
                editor.commands.alignImage(item.key);
                handleOpen();
            },
        };
    });

    const handleAddLink = () => {
        handleAddLinkOpen();
    };

    const handleInsertLink = () => {
        handleAddLink();
    };

    return (
        <div className={`${classes.listButtonsSection} ${getThemeColorClass(darkMode)}`}>
            <div className={`${classes.textAlignDropdown} ${getThemeColorClass(darkMode)}`}>
                <Dropdown
                    open={isOpen}
                    menu={{
                        items: justifyOptionItems,
                        selectedKeys: [getActiveAlign()],
                    }}
                    trigger={['click']}
                    placement='bottom'
                    onOpenChange={handleOpen}
                    getPopupContainer={() => document.getElementById(EDITOR_TOOLBAR_ID)}
                    overlayClassName={`${classes.alignDropdownOverlay} ${getDropdownOverlayClass(
                        darkMode
                    )}`}
                >
                    <Tooltip
                        overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(
                            darkMode
                        )}`}
                        placement='top'
                        title={<CustomButtonsTooltip title='Text align' />}
                    >
                        <Button
                            className={classes.alignButton}
                            icon={
                                <span
                                    className={classes.arrowIcon}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleOpen();
                                    }}
                                >
                                    {isOpen ? (
                                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                                    ) : (
                                        <SiderChevronDownIcon color={iconColor} />
                                    )}
                                </span>
                            }
                            onClick={handleOpen}
                        >
                            {
                                justifyOptions.find((item) => item.key === getActiveAlign())
                                    ?.optionLabel
                            }
                        </Button>
                    </Tooltip>
                </Dropdown>
            </div>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
                title={<CustomButtonsTooltip title='Bulleted list' />}
            >
                <>
                    <Button
                        icon={<UnorderedListIcon color={getIconColorByDarkMode(darkMode)} />}
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        className={editor.isActive('bulletList') ? 'active' : ''}
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
                title={<CustomButtonsTooltip title='Numbered list' />}
            >
                <>
                    <Button
                        icon={<OrderedListIcon color={getIconColorByDarkMode(darkMode)} />}
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        className={editor.isActive('orderedList') ? 'active' : ''}
                    />
                </>
            </Tooltip>

            <KeyPressListener onKeyPress={handleInsertLink} keyAction={keyActionTypes.insertLink} />
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${getTooltipOverlayClass(darkMode)}`}
                title={<CustomButtonsTooltip title='Insert link' />}
            >
                <>
                    <Popover
                        destroyTooltipOnHide
                        open={isAddLinkOpen}
                        content={
                            <AddLinkContent handleCollapse={handleAddLinkOpen} editor={editor} />
                        }
                        trigger={['click']}
                        placement='bottom'
                        className={`${classes.linksDropdown} ${getThemeColorClass(darkMode)}`}
                        overlayClassName={`${classes.linksDropdownOverlay}
                               ${darkMode ? classes.linksDropdownOverlayDark : ''}`}
                        onOpenChange={handleAddLink}
                        getPopupContainer={() => document.getElementById(EDITOR_TOOLBAR_ID)}
                    >
                        <Button
                            icon={<LinkIcon color={getIconColorByDarkMode(darkMode)} />}
                            className={editor.isActive('link') ? 'active' : ''}
                        />
                    </Popover>
                </>
            </Tooltip>
        </div>
    );
};

export default ListButtonsSection;
