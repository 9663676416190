import './App.scss';
import './resources/styles/antd.scss';
import './resources/styles/antd.notification.scss';
import './resources/styles/Baremetrics.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { isAuthorizedRoute } from './utils/helpers/authHelpers';
import { checkIsGlobalTechAdmin } from './utils/helpers/checkIsGlobalTechAdmin';
import { useOrganizationSlug } from './utils/hooks/useOrganizationSlug';

import GlobalAdminRouter from './components/common/router/GlobalAdminRouter';
import Router from './components/common/router/Router';
import UnauthorizedRouter from './components/common/router/Unauthorized/UnauthorizedRouter';

import withAntdConfigProvider from './components/hocs/AntdConfigProvider';
import withAuthenticatorProvider from './components/hocs/AuthenticatorProvider';
import withRouterProvider from './components/hocs/RouterProvider';
import withStoreProvider from './components/hocs/StoreProvider';
import withSubscriptionLimitsProvider from './components/hocs/SubscriptionLimitsProvider';
import { withUppoloProvider } from './components/hocs/UppoloProvider';

import { setIsAddURLsActive } from './components/pages/contentMonitor/mainPage/store/contentMonitorMain.actions';
import { setIsImportURLActive } from './components/pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';

import { selectUserData } from './userBrowserSettings/store/browserSettings.selectors';

import { setupAxiosInterceptor } from './utils/axiosConfig';

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const userData = useSelector(selectUserData);
    const organizationSlug = useOrganizationSlug();

    setupAxiosInterceptor(dispatch, navigate, userData, organizationSlug);

    const getRouter = () => {
        if (userData?.active) {
            return checkIsGlobalTechAdmin(userData?.roles) ? <GlobalAdminRouter /> : <Router />;
        } else {
            return <UnauthorizedRouter />;
        }
    };

    useEffect(() => {
        // If the user is not active and the current route is an authorized route,
        // save the current path to localStorage to redirect back after login.
        if (
            (!userData || !userData?.active) &&
            isAuthorizedRoute(location.pathname, organizationSlug)
        ) {
            localStorage.setItem('backToLink', location.pathname);
        }

        if (location.pathname !== `/${organizationSlug}/optimizer`) {
            // Optimizer Import URL Content toggle
            dispatch(setIsImportURLActive(false));
        }

        if (location.pathname !== `/${organizationSlug}/monitor`) {
            // Monitor Add URLs toggle
            dispatch(setIsAddURLsActive(false));
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <div style={{ height: '100vh' }} className='App'>
            <Helmet>
                <meta name='theme-color' content='#ffffff' />
                {/* The modal window from HelpScout is added here */}
                <script type='text/javascript'>{`!function(e,t,n) {
                    function a(){
                        var e=t.getElementsByTagName("script")[0],n=t.createElement("script");
                        n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)
                    }
                    if(e.Beacon=n=function(t,n,a) {
                        e.Beacon.readyQueue.push({method:t,options:n,data:a})
                    },
                    n.readyQueue=[],"complete"===t.readyState)

                    return a();
                    e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})`}</script>
                <script type='text/javascript'>{`window.Beacon('init', '744a4ffd-a623-48cd-9322-4c4ad0878337')`}</script>
                <script id='profitwell-js' data-pw-auth='a416e00dd70b05bdd6f885b83a25be17'>{`
                (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
                `}</script>
                <script type='text/javascript'>{`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}</script>
                <script
                    async
                    src='https://affiliates.rankability.com/rw.js'
                    data-rewardful='7df96a'
                ></script>
            </Helmet>
            {getRouter()}
        </div>
    );
};

export default withStoreProvider(
    withAntdConfigProvider(
        withAuthenticatorProvider(
            withRouterProvider(withSubscriptionLimitsProvider(withUppoloProvider(App)))
        )
    )
);
