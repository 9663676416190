import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { keywordTaskStatus } from '../../../../constants/keywordTaskStatus';
import { ordering } from '../../../../constants/ordering';
import { keywordPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';

import {
    setCurrentKeyword,
    setInitialKeywordListSort,
    updateKeywordQueryParam,
    updateKeywordResearchTab,
} from '../store/keywordResearchSettings.actions';
import { setErrorStatus } from '../../../../userBrowserSettings/store/browserSettings.actions';
import {
    selectCurrentKeyword,
    selectKeywordListFilters,
    selectKeywordListPagination,
    selectKeywordListSearch,
    selectKeywordListSort,
    selectKeywordResearchTab,
} from '../store/keywordResearchSettings.selectors';

import {
    getKeywordResearchResultsSortQueryByKey,
    keywordResearchResultsSortOptions,
} from '../../../../constants/keywordResearchResultsSortOptions';
import KeywordResearchHeader from '../common/KeywordResearchHeader';
import KeywordListOfResult from './KeywordListOfResult';

const classes = {
    resultsContentWrapper: 'results-content-wrapper',
};

const KeywordResultsContentLayout = ({ keywordId, isListLoading, setIsListLoading }) => {
    const dispatch = useDispatch();

    const selectedTab = useSelector(selectKeywordResearchTab);
    const currentKeyword = useSelector(selectCurrentKeyword);
    const keywordListSort = useSelector(selectKeywordListSort);
    const keywordListPagination = useSelector(selectKeywordListPagination);
    const keywordListSearch = useSelector(selectKeywordListSearch);
    const keywordListFilters = useSelector(selectKeywordListFilters);

    const isUrlTab = selectedTab === 'url';

    const getCurrentKeyword = (is_unmounted = false) => {
        if (is_unmounted || isListLoading) {
            return;
        }
        setIsListLoading(true);

        const urlSortParams = getKeywordResearchResultsSortQueryByKey(
            keywordResearchResultsSortOptions.highRanking
        );

        const sortParams = isUrlTab
            ? { sort: urlSortParams?.field, order: urlSortParams?.order }
            : {
                  sort: keywordListSort?.field,
                  order: ordering[keywordListSort?.order],
              };

        axiosAPI
            .get(
                `${keywordPathes.getKeywordResearchInfo}${
                    currentKeyword ? currentKeyword.id : keywordId
                }`,
                {
                    ...getAxiosHeaders(),
                    params: {
                        ...sortParams,
                        page: keywordListPagination?.page,
                        per_page: keywordListPagination?.perPage,
                    },
                }
            )
            .then((result) => {
                if (!result) {
                    dispatch(setErrorStatus(null));

                    return;
                }

                const taskStatus = result?.data?.task_status;

                if (
                    [keywordTaskStatus.ok, keywordTaskStatus.notFound].includes(taskStatus) ||
                    keywordTaskStatus.error.test(taskStatus)
                ) {
                    // we should set current keyword on DFSEO error or not found
                    // because re-requesting keyword research info will not be sufficient
                    batch(() => {
                        dispatch(setCurrentKeyword(result?.data));
                        dispatch(updateKeywordResearchTab(result?.data?.type));
                        dispatch(
                            updateKeywordQueryParam({
                                type: result?.data?.type,
                            })
                        );
                    });

                    if (
                        taskStatus === keywordTaskStatus.notFound ||
                        keywordTaskStatus.error.test(taskStatus)
                    ) {
                        openBasicErrorNotification();
                    }
                } else {
                    setTimeout(() => getCurrentKeyword(is_unmounted), 2000);
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsListLoading(false);
            });
    };

    useEffect(() => {
        return () => {
            batch(() => {
                dispatch(updateKeywordQueryParam({ search: '', page: 1 }));
                dispatch(setInitialKeywordListSort());
            });
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let unmounted = false;

        if (!currentKeyword?.task_result?.length) {
            if (!isListLoading) {
                getCurrentKeyword(unmounted);
            }

            return () => {
                unmounted = true;
            };
        }
        // eslint-disable-next-line
    }, [
        // since we are setting current keyword on error, it leads to infinite loop
        // so we will only use this effect on mount, unless it will be necessary
        currentKeyword?.id,
    ]);

    useEffect(() => {
        let unmounted = false;

        const getFilterRules = () => {
            if (keywordListFilters) {
                return JSON.stringify(keywordListFilters);
            } else {
                return null;
            }
        };

        if (currentKeyword?.task_result) {
            setIsListLoading(true);

            axiosAPI
                .get(
                    `${keywordPathes.getKeywordResearchInfo}${
                        currentKeyword ? currentKeyword.id : keywordId
                    }`,
                    {
                        ...getAxiosHeaders(),
                        params: {
                            sort: keywordListSort?.field,
                            order: keywordListSort?.order,
                            search: keywordListSearch || null,
                            page: keywordListPagination?.page,
                            per_page: keywordListPagination?.perPage,
                            filter_rules: getFilterRules(),
                        },
                    }
                )
                .then((result) => {
                    if (
                        result?.status === statusCodes.success &&
                        (result?.data?.task_result || result?.data?.task_status === 'Ok.') &&
                        !unmounted
                    ) {
                        dispatch(setCurrentKeyword(result?.data));

                        if (result?.data?.task_status === keywordTaskStatus.notFound) {
                            openBasicErrorNotification();
                        }
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsListLoading(false);
                });
        }

        return () => {
            unmounted = true;
            setIsListLoading(false);
        };

        // eslint-disable-next-line
    }, [
        keywordListSort,
        keywordListPagination.page,
        keywordListPagination.perPage,
        keywordListSearch,
        keywordListFilters,
    ]);

    return (
        <div className={classes.resultsContentWrapper}>
            <KeywordResearchHeader />

            <KeywordListOfResult isListLoading={isListLoading} />
        </div>
    );
};

export default KeywordResultsContentLayout;
