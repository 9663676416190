import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Popconfirm, Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { contentMonitorPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../utils/helpers/openBasicErrorNotification';
import { getDeletePopconfirmClass, getThemeColorClass } from '../../../utils/helpers/themeHelpers';

import ManageMonitorsProjectModalWrapper from './ManageMonitorsProjectModalWrapper';

import '../checkedItemsActionsPanel/CheckedItemsActionsPanel.scss';

const { Link } = Typography;

const classes = {
    checkedItemsActionsPanel: 'checked-items-actions-panel d-flex align-items-center',
    checkedItemsActionsPanelBtn: 'checked-items-actions-panel-btn',
    checkedItemsActionsPanelLink: 'selected-link',
};

const CheckedMonitorsItemsActionsPanel = ({
    checkedItems,
    selectedProjects,
    handleDeleteAction,
    handleUpdateProjects,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleDelete = () => {
        setIsDeleteLoading(true);

        axiosAPI
            .delete(`${contentMonitorPathes.baseContentMonitorUrl}`, {
                ...getAxiosHeaders(),
                data: {
                    ids: checkedItems,
                },
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    handleDeleteAction(result.data);
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });
    };

    return (
        <div className={classes.checkedItemsActionsPanel}>
            <Link
                className={classes.checkedItemsActionsPanelLink}
            >{`Selected Monitors (${checkedItems.length})`}</Link>
            <ManageMonitorsProjectModalWrapper
                checkedItems={checkedItems}
                selectedProjects={selectedProjects}
                handleUpdateProjects={handleUpdateProjects}
            />
            <Popconfirm
                placement='topRight'
                title='Are you sure you want to delete?'
                onConfirm={handleDelete}
                okText='Yes'
                cancelText='Cancel'
                loading={isDeleteLoading}
                overlayClassName={`${getDeletePopconfirmClass(darkMode)}`}
            >
                <Button
                    className={`${classes.checkedItemsActionsPanelBtn} ${
                        getThemeColorClass(darkMode)
                    }`}
                    type='primary'
                    ghost
                >
                    Delete
                </Button>
            </Popconfirm>
        </div>
    );
};

export default CheckedMonitorsItemsActionsPanel;
