import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Checkbox, List, Typography } from 'antd';
import axios from 'axios';

import {
    selectDarkMode,
    selectViewMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { renderPaginationArrows } from '../../../../utils/helpers/renderPaginationArrows';
import { getThemeColorClass } from '../../../../utils/helpers/themeHelpers';
import { usePrevious } from '../../../../utils/hooks/usePrevious';

import { keywordResearchTabs } from '../../../../constants/keywordResearchTabs';
import { keywordPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';
import { viewModeType } from '../../../../constants/viewModeType';

import CheckedItemsActionsPanel from '../../../common/checkedItemsActionsPanel/CheckedItemsActionsPanel';
import Loader from '../../../common/loader/Loader';
import KeywordResearchListItem from './KeywordResearchListItem';

import {
    removeKeywordResearch,
    setKeywordResearchChecked,
    setKeywordResearchData,
    updateKeywordQueryParam,
} from '../store/keywordResearchSettings.actions';
import {
    selectKeywordQueryParams,
    selectKeywordResearchChecked,
    selectKeywordResearchData,
    selectKeywordResearchTab,
} from '../store/keywordResearchSettings.selectors';

const { Title } = Typography;

const classes = {
    listHeaderWrapper: 'list-header-wrapper d-flex justify-content-between align-items-center',
    listHeaderTitle: 'list-header-title d-flex align-items-center',
    listWrapper: 'list-wrapper',
    kanbanListWrapper: 'keyword-research-kanban-list-wrapper',
    loaderWrapper: 'keyword-research-loader-wrapper',
};

const KeywordResearchList = ({ isLoading, setIsLoading }) => {
    const dispatch = useDispatch();

    const checkedKeywords = useSelector(selectKeywordResearchChecked);
    const darkMode = useSelector(selectDarkMode);
    const keywordResearchData = useSelector(selectKeywordResearchData);
    const selectedTab = useSelector(selectKeywordResearchTab);
    const listViewMode = useSelector(selectViewMode);

    const queryParams = useSelector(selectKeywordQueryParams);
    const page = queryParams.page;
    const perPage = queryParams.per_page;

    const prevQueryParams = usePrevious(queryParams);

    const [total, setTotal] = useState(0);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams)) {
            setIsLoading(true);

            axiosAPI
                .get(
                    keywordPathes.getListOfKeywordResearches,
                    { params: queryParams },
                    {
                        cancelToken: source.token,
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setKeywordResearchData(result?.data));
                        setTotal(result?.headers?.['x-total-pages'] * perPage);
                    }
                })
                .catch((_error) => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => {
            source.cancel('axios request cancelled');
        };

        // eslint-disable-next-line
    }, [queryParams]);

    const handleChangePage = (page) => {
        dispatch(updateKeywordQueryParam({ page: page }));
    };

    useEffect(() => {
        setIndeterminate(
            checkedKeywords.length && checkedKeywords.length !== keywordResearchData?.length
        );
        setCheckAll(
            checkedKeywords.length && checkedKeywords.length === keywordResearchData?.length
        );
    }, [checkedKeywords, keywordResearchData?.length]);

    const onCheckAllChange = (e) => {
        dispatch(
            setKeywordResearchChecked(
                e.target.checked ? keywordResearchData?.map((item) => item?.id) : []
            )
        );
        setCheckAll(e.target.checked);
    };

    const handleDeleteAction = (result) => {
        batch(() => {
            dispatch(removeKeywordResearch(checkedKeywords));
            dispatch(setKeywordResearchChecked([]));
            dispatch(setKeywordResearchData(result?.data?.keyword_researches || []));
            setTotal(result?.headers?.['x-total-pages'] * perPage);
        });
    };

    return (
        <>
            <div className={classes.listHeaderWrapper}>
                <div className={classes.listHeaderTitle}>
                    {keywordResearchData?.length > 0 && (
                        <div className='d-flex align-items-center'>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                disabled={isLoading}
                            />

                            <div className='d-flex align-items-center'>
                                <Title level={3} className={classes.listHeaderTitle}>
                                    {`Recent ${
                                        selectedTab === keywordResearchTabs.keyword.key
                                            ? 'Keywords'
                                            : 'URLs'
                                    }`}
                                </Title>
                            </div>
                        </div>
                    )}
                    {checkedKeywords?.length > 0 && (
                        <CheckedItemsActionsPanel
                            selectedTab={selectedTab}
                            checkedItems={checkedKeywords}
                            queryParams={queryParams}
                            showOnlyDeleteAction={true}
                            handleDeleteAction={handleDeleteAction}
                            isKeywordResearchBulkDelete
                        />
                    )}
                </div>
            </div>
            <Checkbox.Group
                value={checkedKeywords}
                onChange={(checkedValues) => {
                    dispatch(setKeywordResearchChecked(checkedValues));
                }}
            >
                <List
                    grid={listViewMode === viewModeType.kanban && { gutter: 20, column: 4 }}
                    className={`${classes.listWrapper} ${
                        listViewMode === viewModeType.kanban && classes.kanbanListWrapper
                    } ${getThemeColorClass(darkMode)}`}
                    dataSource={keywordResearchData || []}
                    pagination={{
                        pageSize: perPage,
                        defaultCurrent: page,
                        current: page,
                        total: total,
                        onChange: handleChangePage,
                        itemRender: renderPaginationArrows,
                        showSizeChanger: false,
                    }}
                    renderItem={(item) => (
                        <KeywordResearchListItem key={item.id} keywordItem={item} />
                    )}
                    loading={{
                        spinning: isLoading && !keywordResearchData,
                        indicator: <Loader />,
                    }}
                />
            </Checkbox.Group>
        </>
    );
};

export default KeywordResearchList;
