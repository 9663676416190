import React, { useState } from 'react';

import { Input, Switch, Typography } from 'antd';

import { contentOptimizerPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';
import CopyIcon from '../../../resources/icons/CopyIcon';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../utils/helpers/openBasicErrorNotification';
import { useIconColor } from '../../../utils/hooks/useIconColor';
import { useOpen } from '../../../utils/hooks/useOpen';

import CopyItem from '../copyItem/CopyItem';
import CustomDropdown from './CustomDropdown';

const { Title } = Typography;

const classes = {
    shareLinkWrapper: 'share-link-wrapper',
    toggleContainer: 'toggle-container',
};

const SharePageDropdown = ({
    optimizerId,
    shareLink,
    children,
    isShared,
    onChangedSharedCallback,
}) => {
    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleToggleChange = (checked) => {
        setIsDisabled(true);
        axiosAPI
            .patch(
                `${contentOptimizerPathes.editContentOptimizer}/${optimizerId}`,
                {
                    shared: checked,
                },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    onChangedSharedCallback && onChangedSharedCallback(result.data);
                }
            })
            .catch(() => openBasicErrorNotification())
            .finally(() => setIsDisabled(false));
    };

    return (
        <CustomDropdown
            withExtraContent
            withArrow
            dropdownOpenElement={children}
            dropdownContent={
                <>
                    <div className={classes.toggleContainer}>
                        <Switch
                            disabled={isDisabled}
                            checked={isShared}
                            onChange={handleToggleChange}
                            size='small'
                        />
                        <Title level={5}>Enable shared link</Title>
                    </div>
                    <Title level={5}>
                        {isShared
                            ? 'Anyone with the link below can view and edit this Content Optimizer:'
                            : 'This Optimizer can only be accessed by members of your organization.'}
                    </Title>

                    {isShared && (
                        <div className={classes.shareLinkWrapper}>
                            <Input
                                value={shareLink || ''}
                                type='text'
                                readOnly
                                addonAfter={
                                    <CopyItem
                                        customCopyIcon={CopyIcon}
                                        customIconColor={iconColor}
                                        copiedText={shareLink || ''}
                                    />
                                }
                            />
                        </div>
                    )}
                </>
            }
            dropdownOverlayClassName='share-dropdown-overlay'
            dropdownTriggersArray={['click']}
            isDropdownOpen={isOpen}
            handleOpenChange={handleOpen}
        />
    );
};

export default SharePageDropdown;
