import { useEffect, useRef } from 'react';

export const useProjectsWrapperRef = () => {
    const formWrapperRef = useRef(null);

    useEffect(() => {
        if (formWrapperRef?.current) {
            const maxWrapperHeightNoScroll = 401;
            const wrapperHeight = formWrapperRef?.current.offsetHeight;

            formWrapperRef.current.style.setProperty(
                'padding-right',
                wrapperHeight > maxWrapperHeightNoScroll ? '20px' : '0'
            );
        }
    }, [formWrapperRef?.current?.offsetHeight]);

    return formWrapperRef;
};
