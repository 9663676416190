import { axiosAPI } from './axiosAPI';

import { cleanUpUserData, isAuthorizedRoute, isUserAuthorized } from './helpers/authHelpers';
import { checkIsBelowAdmin } from './helpers/checkIsAdmin';
import RuptService from './helpers/RuptService';

import { unauthorizedRoutes } from '../components/common/router/Unauthorized/routes';

import {
    setErrorStatus,
    setIsSubscriptionExpired,
} from '../userBrowserSettings/store/browserSettings.actions';

import { statusCodes } from '../constants/statusCodes';

const handleUnauthorizedError = (dispatch, navigate, organizationSlug) => {
    if (isUserAuthorized() || isAuthorizedRoute(window.location.pathname, organizationSlug)) {
        // cleaning up user data and navigating to the login page.
        cleanUpUserData(dispatch);
        navigate(unauthorizedRoutes.login);
    }
};

const handleSubscriptionExpiredError = async (dispatch, navigate, userData) => {
    dispatch(setIsSubscriptionExpired(true));

    if (checkIsBelowAdmin(userData?.roles)) {
        // detaching the device and navigating to the login page.
        await RuptService.detachDevice(String(userData?.id));
        cleanUpUserData(dispatch);
        navigate(unauthorizedRoutes.login);
    }
};

export const setupAxiosInterceptor = (dispatch, navigate, userData, organizationSlug) => {
    // sets up the Axios interceptor for handling API responses.
    axiosAPI.interceptors.response.use(
        (response) => response,
        (error) => {
            const { status } = error.response;

            switch (status) {
                case statusCodes.unauthorized:
                    handleUnauthorizedError(dispatch, navigate, organizationSlug);
                    break;
                case statusCodes.forbidden:
                case statusCodes.notFound:
                    dispatch(setErrorStatus(status));
                    break;
                case statusCodes.subscriptionExpired:
                    return handleSubscriptionExpiredError(dispatch, navigate, userData);
                default:
                    return Promise.reject(error);
            }
        }
    );
};
