import { themeType } from '../../constants/themeType';

export const getThemeColorClass = (darkMode = false) => {
    return darkMode ? 'dark-mode' : '';
};

export const getTooltipOverlayClass = (darkMode = false) => {
    return `tooltip-overlay ${darkMode ? 'tooltip-overlay-dark' : ''}`;
};

export const getDropdownOverlayClass = (darkMode = false) => {
    return `dropdown-overlay ${darkMode ? 'dropdown-overlay-dark' : ''}`;
};

export const getDeletePopconfirmClass = (darkMode = false) => {
    return `delete-popconfirm ${darkMode ? 'delete-popconfirm-dark' : ''}`;
};

export const getTheme = (darkMode = false) => {
    return darkMode ? themeType.dark : themeType.light;
};
