export const updateProjectNames = (projects, updatedProjects = [], defaultValue = []) => {
    if (!updatedProjects?.length) return projects || defaultValue;

    return (
        projects?.map((project) => {
            const newName =
                updatedProjects.find((projectToUpdate) => project.id === projectToUpdate.id)
                    ?.name || project.name;

            return {
                ...project,
                name: newName,
            };
        }) || defaultValue
    );
};

export const removeProjectByIds = (projects, updatedProjects = [], defaultValue = []) => {
    return projects?.filter((project) => !updatedProjects.includes(project.id)) || defaultValue;
};
