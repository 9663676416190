import { roleTypes } from '../../constants/roleTypes';

export const checkIsAdmin = (roleKey) => {
    return (
        roleTypes.organizationOwner.key === roleKey ||
        roleTypes.organizationAdmin.key === roleKey
    );
};

export const checkIsBelowAdmin = (roles) => {
    return roles?.every(
        (role) =>
            Object.values(roleTypes).find((type) => type.key === role.name)?.rating <
            roleTypes.organizationAdmin.rating
    );
};
