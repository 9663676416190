import { globalAdminPathes } from '../constants/queryPathes';
import { axiosAPI } from '../utils/axiosAPI';
import { getAxiosHeaders } from '../utils/helpers/getAxiosHeaders';

const AdminActionsService = {
    updateOrganizationSettings: (organizationId, settings) => {
        if (!organizationId || !settings) return;

        return axiosAPI.patch(
            `${globalAdminPathes.changeOrganization}/${organizationId}`,
            { settings },
            { ...getAxiosHeaders() }
        );
    },
};

export default AdminActionsService;
