const sessionStorageKeys = {
    EDITOR_LOGOUT: 'editor-logout',
};

const SessionStorageService = {
    getItem: (key) => {
        return JSON.parse(sessionStorage.getItem(key));
    },
    setItem: (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    getEditorLogout: () => {
        return SessionStorageService.getItem(sessionStorageKeys.EDITOR_LOGOUT);
    },
    setEditorLogout: (value) => {
        SessionStorageService.setItem(sessionStorageKeys.EDITOR_LOGOUT, value);
    },
};

export default SessionStorageService;
