import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Form, Input, Modal, Spin, Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import CloseIcon from '../../../resources/icons/CloseIcon';
import PlusIcon from '../../../resources/icons/Plus';
import { colorPalette } from '../../../resources/styles/colorPalette';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../utils/helpers/openBasicErrorNotification';
import { useProjectsWrapperRef } from '../../../utils/hooks/useProjectsWrapperRef';

import { statusCodes } from '../../../constants/statusCodes';
import { contentBriefPathes, projectPathes } from '../../../constants/queryPathes';

import {
    addProject,
    removeContentOptimizerProjectIds,
    updateContentOptimizerMainQueryParam,
    updateContentOptimizerProjects,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';
import {
    selectContentBriefsProjects,
    selectContentOptimizersMainProject,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.selectors';

import CustomCheckboxList from '../checkboxes/CustomCheckboxList';
import ErrorMessage from '../errorMessage/ErrorMessage';
import Loader from '../loader/Loader';

import { projectsMethods } from './projectsMethods';

const { Title, Link } = Typography;

const classes = {
    manageProjectBtn: 'checked-items-actions-panel-btn manage-project-btn',
    modalWrapper: 'modal-wrapper manage-project-modal',
    modalWrapperDark: 'modal-wrapper-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalInputWrapper: 'input-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalLink: 'manage-project-modal-link',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    availableProjectsWrapper: 'available-projects-wrapper',
    noProjectMessage: 'no-project-message',
    editDeleteProjectButtons: 'edit-delete-project-buttons d-flex',
};

const ManageProjectModal = ({
    getOptimizers,
    isOpen,
    handleOpen,
    checkedItems,
    selectedProjects,
    handleUpdateProjects,
}) => {
    const dispatch = useDispatch();
    const formWrapperRef = useProjectsWrapperRef();

    const [form] = Form.useForm();
    const newProjectName = Form.useWatch('newProjectName', form);

    const darkMode = useSelector(selectDarkMode);
    const coQueryProjects = useSelector(selectContentOptimizersMainProject);
    const existedProjects = useSelector(selectContentBriefsProjects);

    const [fetchedProjects, setFetchedProjects] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [checkedAvailableProjects, setCheckedAvailableProjects] = useState([]);
    const [isProjectsLoading, setIsProjectsLoading] = useState(false);
    const [projectsError, setProjectsError] = useState(false);

    const [currentlySelectedProjects, setCurrentlySelectedProjects] = useState([]);
    const [isAddNewProjectLoading, setIsAddNewProjectLoading] = useState(false);
    const [addNewProjectError, setAddNewProjectError] = useState(false);

    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState(false);

    const [newProjectAdded, setNewProjectAdded] = useState(false);

    useEffect(() => {
        if (selectedProjects?.length && isOpen) {
            // Get projects for selected briefs
            const selectedForAllProjects = projectsMethods
                .getArrayOfProjectsSelectedForAllBriefs(selectedProjects)
                .filter(
                    (selectedProject) =>
                        !!existedProjects.find((project) => project.id === selectedProject.id)
                );

            setCurrentlySelectedProjects(selectedForAllProjects);

            // Get projects for checked available briefs
            const selectedForSomeProjects = projectsMethods.getArrayOfProjectsSelectedForSomeBriefs(
                selectedProjects,
                selectedForAllProjects
            );
            setCheckedAvailableProjects(selectedForSomeProjects);
        } else {
            setCurrentlySelectedProjects([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjects, isOpen]);

    useEffect(() => {
        // Filter all projects to get available projects
        if (isOpen) {
            setAvailableProjects(
                fetchedProjects?.filter(
                    (project) =>
                        !currentlySelectedProjects?.some(
                            (selectedProject) => selectedProject?.id === project?.id
                        )
                )
            );
        }
    }, [isOpen, currentlySelectedProjects, fetchedProjects]);

    useEffect(() => {
        if (isOpen) {
            setIsProjectsLoading(true);

            axiosAPI
                .get(
                    `${projectPathes.getListOfProjects}`,
                    { params: { with_briefs: true } },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        setFetchedProjects(result.data);
                    }
                })
                .catch(() => {
                    setProjectsError(true);
                })
                .finally(() => {
                    setIsProjectsLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        form.setFieldValue(
            'selected',
            currentlySelectedProjects?.map((project) => project.id) || []
        );
    }, [currentlySelectedProjects, form]);

    useEffect(() => {
        form.setFieldValue(
            'available',
            checkedAvailableProjects?.map((project) => project.id) || []
        );
    }, [checkedAvailableProjects, form]);

    useEffect(() => {
        form.setFields([
            {
                name: 'newProjectName',
                errors: [],
            },
        ]);
    }, [newProjectName, form]);

    const isProjectNameUnique = (nameToValidate, idToValidate = null) => {
        if (!idToValidate) {
            return (
                !!nameToValidate?.length && !fetchedProjects.some((p) => p.name === nameToValidate)
            );
        }

        return (
            !!nameToValidate?.length &&
            !fetchedProjects.some((p) => p.name === nameToValidate && idToValidate !== p.id)
        );
    };

    const handleAddNewProject = () => {
        if (newProjectName && isProjectNameUnique(newProjectName)) {
            setIsAddNewProjectLoading(true);

            axiosAPI
                .post(
                    `${projectPathes.createProject}`,
                    {
                        name: newProjectName,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        dispatch(addProject(result.data));
                        setCurrentlySelectedProjects([result.data, ...currentlySelectedProjects]);
                        setFetchedProjects([result.data, ...fetchedProjects]);
                        form.setFieldValue('newProjectName', '');

                        setNewProjectAdded(true);
                    }
                })
                .catch(() => {
                    setAddNewProjectError(true);
                })
                .finally(() => {
                    setIsAddNewProjectLoading(false);
                });
        } else {
            form.setFields([
                {
                    name: 'newProjectName',
                    errors: ['Project already exists'],
                },
            ]);
        }
    };

    const handleEditProjectName = (id, value) => {
        const trimmedValue = value.trim();

        if (id && trimmedValue) {
            if (!isProjectNameUnique(trimmedValue, id)) {
                return {
                    error: 'Project already exists',
                };
            }

            axiosAPI
                .patch(
                    `${projectPathes.editProject}/${id}`,
                    {
                        name: trimmedValue,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.data) {
                        dispatch(updateContentOptimizerProjects([result.data]));
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                });
        }
    };

    const handleDeleteProjectById = (id) => {
        if (id) {
            axiosAPI
                .delete(`${projectPathes.editProject}/${id}`, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.noContent) {
                        dispatch(removeContentOptimizerProjectIds([id]));
                        setCurrentlySelectedProjects((prev) => [
                            ...prev.filter((project) => project.id !== id),
                        ]);
                        setFetchedProjects((prev) => [
                            ...prev.filter((project) => project.id !== id),
                        ]);
                        setAvailableProjects((prev) => [
                            ...prev.filter((project) => project.id !== id),
                        ]);
                        setCheckedAvailableProjects((prev) => [
                            ...prev.filter((project) => project.id !== id),
                        ]);
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {});
        }
    };

    const handleSelectAvailableProject = (e) => {
        const { checked } = e.target;
        if (checked) {
            setCurrentlySelectedProjects([
                availableProjects.find((p) => p.id === e.target.value),
                ...currentlySelectedProjects,
            ]);
        } else {
            setCheckedAvailableProjects(
                checkedAvailableProjects.filter((p) => p.id !== e.target.value)
            );
        }
    };

    const handleSelectSelectedProject = (e) => {
        setCurrentlySelectedProjects(
            currentlySelectedProjects.filter((p) => p.id !== e.target.value)
        );
        setFetchedProjects((prev) => {
            if (prev.some((p) => p.id === e.target.value)) {
                return prev;
            } else {
                return [...prev, fetchedProjects.find((p) => p.id === e.target.value)];
            }
        });
    };

    const handleApply = () => {
        const checkedProjects = [...currentlySelectedProjects, ...checkedAvailableProjects];

        const projectsToRemove = projectsMethods.getArrayOfProjectsToRemove(
            selectedProjects,
            checkedProjects
        );

        axiosAPI
            .patch(
                `${contentBriefPathes.bulkUpdateContentBriefs}`,
                {
                    ids: checkedItems,
                    content_brief: {
                        project_ids_add: currentlySelectedProjects.map((project) => project.id),
                        project_ids_remove: projectsToRemove.map((project) => project.id),
                    },
                },
                {
                    ...getAxiosHeaders(),
                    params: {
                        get_optimizers: getOptimizers,
                    },
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    const filteredToProjectsResult = getOptimizers
                        ? result?.data?.map((item) => item?.content_brief?.projects)
                        : result?.data?.map((item) => item.projects);

                    const numOfInitialBriefsWithProjects =
                        projectsMethods.getNumberOfItemsWithProjects(selectedProjects);
                    const numOfBriefsWithProjectsAfterUpdate =
                        projectsMethods.getNumberOfItemsWithProjects(filteredToProjectsResult);

                    const briefsAssignedToProjectsDiff =
                        numOfBriefsWithProjectsAfterUpdate - numOfInitialBriefsWithProjects;

                    handleUpdateProjects(result?.data, briefsAssignedToProjectsDiff);
                    handleOpen();
                    deleteProjectsWithoutBriefs();
                }
            })
            .catch(() => {
                setUpdateError(true);
            })
            .finally(() => {
                setIsUpdateLoading(false);
                setNewProjectAdded(false);
            });
    };

    const handleCloseModal = () => {
        form.resetFields();
        setCurrentlySelectedProjects([]);
        setCheckedAvailableProjects([]);
        setAvailableProjects([]);
        setProjectsError(false);
        setAddNewProjectError(false);
        setUpdateError(false);

        if (newProjectAdded) {
            deleteProjectsWithoutBriefs();
        }
    };

    const deleteProjectsWithoutBriefs = () => {
        axiosAPI
            .delete(`${projectPathes.getListOfProjects}`, {
                params: { without_briefs: true },
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (
                    result?.data?.deleted?.some(
                        (deleteProjectId) => deleteProjectId === Number(coQueryProjects)
                    )
                ) {
                    dispatch(updateContentOptimizerMainQueryParam({ projects: null }));
                }

                setNewProjectAdded(false);
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const showAddProjectLink = newProjectName && !isAddNewProjectLoading;

    return (
        <Modal
            centered
            title='Manage Project'
            open={isOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            wrapClassName={`${classes.modalWrapper} ${darkMode ? classes.modalWrapperDark : ''}`}
            afterClose={handleCloseModal}
        >
            {isProjectsLoading && <Loader />}
            {projectsError && <ErrorMessage />}
            {!isProjectsLoading && !projectsError && (
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.profileForm}
                    onFinish={handleApply}
                >
                    <Spin spinning={isAddNewProjectLoading} indicator={<Loader />}>
                        <div className={classes.modalFormFieldsWrapper} ref={formWrapperRef}>
                            <div className={classes.modalInputWrapper}>
                                {(addNewProjectError || updateError) && <ErrorMessage />}
                                <Form.Item label='Project name' name='newProjectName'>
                                    <Input placeholder='Enter your project name' />
                                </Form.Item>
                                <Link
                                    className={`${classes.modalLink}${
                                        showAddProjectLink ? '' : ' hidden-link'
                                    }`}
                                    onClick={showAddProjectLink ? handleAddNewProject : () => null}
                                >
                                    <PlusIcon color={colorPalette.colorPrimary} /> Add new Project
                                </Link>
                            </div>
                            {currentlySelectedProjects?.length > 0 && (
                                <>
                                    <Title level={5} className={classes.modalSubtitle}>
                                        Selected
                                    </Title>
                                    <Form.Item name='selected'>
                                        <Checkbox.Group>
                                            <CustomCheckboxList
                                                itemList={currentlySelectedProjects}
                                                checkedByDefault={true}
                                                itemKeyName='selected'
                                                itemWrapperClass='edit-delete-project-buttons'
                                                handleSelectItem={handleSelectSelectedProject}
                                                handleEditName={handleEditProjectName}
                                                handleDeleteById={handleDeleteProjectById}
                                            />
                                        </Checkbox.Group>
                                    </Form.Item>
                                </>
                            )}
                            <Title level={5} className={classes.modalSubtitle}>
                                Available
                            </Title>
                            <Form.Item
                                name='available'
                                className={classes.availableProjectsWrapper}
                            >
                                {availableProjects?.length > 0 && !isAddNewProjectLoading ? (
                                    <Checkbox.Group>
                                        <CustomCheckboxList
                                            itemList={availableProjects}
                                            itemKeyName='available'
                                            itemWrapperClass='edit-delete-project-buttons'
                                            handleSelectItem={handleSelectAvailableProject}
                                            handleEditName={handleEditProjectName}
                                            handleDeleteById={handleDeleteProjectById}
                                        />
                                    </Checkbox.Group>
                                ) : (
                                    <Typography className={classes.noProjectMessage}>
                                        No available projects
                                    </Typography>
                                )}
                            </Form.Item>
                        </div>
                    </Spin>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                            className={`${darkMode ? 'dark-mode' : ''}`}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            loading={isUpdateLoading}
                        >
                            Apply
                        </Button>
                    </div>
                </Form>
            )}
        </Modal>
    );
};

export default ManageProjectModal;
