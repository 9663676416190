export const KEY_CODES = {
    ENTER: 'Enter',
};

export const RIGHT_SINGLE_QUOTATION_MARK = '\u2019';
export const SPECIFIC_LETTER_UNICODES = {
    à: '\u00E0',
    á: '\u00E1',
    â: '\u00E2',
    ä: '\u00E4',
    ã: '\u00E3',
    ù: '\u00F9',
    é: '\u00E9',
};

export const FILTERED_TERMS = 'filtered_terms';

export const MIN_KEYWORD_LENGTH = 2;
export const MAX_KEYWORD_LENGTH = 80;

export const MAX_CUSTOM_KEYWORD_LENGTH = 60;
