export const ConfirmEmailStatusCode = {
    ALREADY_CONFIRMED: 'already_confirmed',
    EXPIRED_AND_NOT_CONFIRMED: 'expired_and_not_confirmed',
    INVALID: 'invalid',
};

export const localStorageUserKey = 'user-for-plan-selection';

export const LoginStatusCode = {
    CONFIRMATION_REQUIRED: 'confirmation_required',
    DISABLED_ACCOUNT: 'disabled_account',
    PAYMENT_PLAN_REQUIRED: 'payment_plan_required',
};

export const resendDefaultTimerValueInSeconds = 60;
