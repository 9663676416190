export const contentOptimizerMainActions = {
    CLEAR_CONTENT_OPTIMIZER_MAIN: 'CONTENT_OPTIMIZER_MAIN/CLEAR_CONTENT_OPTIMIZER_MAIN',

    UPDATE_CONTENT_OPTIMIZER_MAIN_TAB: 'CONTENT_OPTIMIZER_MAIN/UPDATE_CONTENT_OPTIMIZER_MAIN_TAB',
    SET_CONTENT_OPTIMIZERS_CHECKED: 'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZERS_CHECKED',
    SET_CONTENT_OPTIMIZER_DATA: 'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZER_DATA',
    MULTIPLE_UPDATE_CONTENT_OPTIMIZER_DATA:
        'CONTENT_OPTIMIZER_MAIN/MULTIPLE_UPDATE_CONTENT_OPTIMIZER_DATA',
    UPDATE_CONTENT_OPTIMIZER_DATA: 'CONTENT_OPTIMIZER_MAIN/UPDATE_CONTENT_OPTIMIZER_DATA',
    UPDATE_CONTENT_OPTIMIZER_SHARED: 'CONTENT_OPTIMIZER_MAIN/UPDATE_CONTENT_OPTIMIZER_SHARED',
    REMOVE_CONTENT_OPTIMIZER_DATA: 'CONTENT_OPTIMIZER_MAIN/REMOVE_CONTENT_OPTIMIZER_DATA',
    ADD_CONTENT_OPTIMIZER_DATA: 'CONTENT_OPTIMIZER_MAIN/ADD_CONTENT_OPTIMIZER_DATA',
    UPDATE_CONTENT_OPTIMIZER_PROJECTS: 'CONTENT_OPTIMIZER_MAIN/UPDATE_CONTENT_OPTIMIZER_PROJECTS',
    REMOVE_CONTENT_OPTIMIZER_PROJECT_IDS:
        'CONTENT_OPTIMIZER_MAIN/REMOVE_CONTENT_OPTIMIZER_PROJECT_IDS',
    UPDATE_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM:
        'CONTENT_OPTIMIZER_MAIN/UPDATE_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM',
    CLEAR_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM:
        'CONTENT_OPTIMIZER_MAIN/CLEAR_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM',
    SET_CONTENT_OPTIMIZER_MAIN_TOTAL_COUNT:
        'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZER_MAIN_TOTAL_COUNT',
    SET_CONTENT_OPTIMIZERS_COUNT: 'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZERS_COUNT',
    INCREASE_OPTIMIZERS_COUNT: 'CONTENT_OPTIMIZER_MAIN/INCREASE_OPTIMIZERS_COUNT',
    DECREASE_OPTIMIZERS_COUNT: 'CONTENT_OPTIMIZER_MAIN/DECREASE_OPTIMIZERS_COUNT',

    SET_PROJECTS: 'CONTENT_OPTIMIZER_MAIN/SET_PROJECTS',
    ADD_PROJECT: 'CONTENT_OPTIMIZER_MAIN/ADD_PROJECT',

    SET_IS_IMPORT_URL_ACTIVE: 'CONTENT_OPTIMIZER_MAIN/SET_IS_IMPORT_URL_ACTIVE',

    SET_CONTENT_OPTIMIZER_FOR_UPDATE: 'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZER_FOR_UPDATE',

    SET_CONTENT_OPTIMIZER_TAB_KEY: 'CONTENT_OPTIMIZER_MAIN/SET_CONTENT_OPTIMIZER_TAB_KEY',
};

export const setIsImportURLActive = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_IS_IMPORT_URL_ACTIVE,
        payload,
    };
};

export const setProjects = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_PROJECTS,
        payload,
    };
};

export const addProject = (payload) => {
    return {
        type: contentOptimizerMainActions.ADD_PROJECT,
        payload,
    };
};

export const multipleUpdateContentOptimizerData = (payload) => {
    return {
        type: contentOptimizerMainActions.MULTIPLE_UPDATE_CONTENT_OPTIMIZER_DATA,
        payload,
    };
};

export const updateContentOptimizerMainTab = (payload) => {
    return {
        type: contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_MAIN_TAB,
        payload,
    };
};

export const setContentOptimizerData = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_DATA,
        payload,
    };
};

export const updateContentOptimizerData = (payload) => {
    return {
        type: contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_DATA,
        payload,
    };
};

export const updateContentOptimizerShared = (payload) => {
    return {
        type: contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_SHARED,
        payload,
    };
};

export const removeContentOptimizerData = (payload) => {
    return {
        type: contentOptimizerMainActions.REMOVE_CONTENT_OPTIMIZER_DATA,
        payload,
    };
};

export const updateContentOptimizerProjects = (payload = []) => {
    return {
        type: contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_PROJECTS,
        payload,
    };
};

export const removeContentOptimizerProjectIds = (payload = []) => {
    return {
        type: contentOptimizerMainActions.REMOVE_CONTENT_OPTIMIZER_PROJECT_IDS,
        payload,
    };
};

export const addContentOptimizerData = (payload) => {
    return {
        type: contentOptimizerMainActions.ADD_CONTENT_OPTIMIZER_DATA,
        payload,
    };
};

export const setContentOptimizersChecked = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZERS_CHECKED,
        payload,
    };
};

export const updateContentOptimizerMainQueryParam = (payload) => {
    return {
        type: contentOptimizerMainActions.UPDATE_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM,
        payload,
    };
};

export const clearContentOptimizerMainQueryParam = () => {
    return {
        type: contentOptimizerMainActions.CLEAR_CONTENT_OPTIMIZER_MAIN_QUERY_PARAM,
    };
};

export const setContentOptimizerMainTotalCount = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_MAIN_TOTAL_COUNT,
        payload,
    };
};

export const setContentOptimizersCount = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZERS_COUNT,
        payload,
    };
};

export const increaseOptimizersCount = (payload) => {
    return {
        type: contentOptimizerMainActions.INCREASE_OPTIMIZERS_COUNT,
        payload,
    };
};

export const decreaseOptimizersCount = (payload) => {
    return {
        type: contentOptimizerMainActions.DECREASE_OPTIMIZERS_COUNT,
        payload,
    };
};

export const clearContentOptimizerMain = () => {
    return {
        type: contentOptimizerMainActions.CLEAR_CONTENT_OPTIMIZER_MAIN,
    };
};

export const setContentOptimizerForUpdate = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_FOR_UPDATE,
        payload,
    };
};

export const setContentOptimizerTabKey = (payload) => {
    return {
        type: contentOptimizerMainActions.SET_CONTENT_OPTIMIZER_TAB_KEY,
        payload,
    };
};
