import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Layout } from 'antd';

import { contentBriefPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { ContentOptimizerContext } from '../../../hocs/ContentOptimizerProvider';

import { elementsStyles } from '../../../../resources/styles/elementsStyles';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { useCollapse } from '../../../../utils/hooks/useCollapse';

import ViewOnlySidebar from '../../../common/viewOnly/ViewOnlySidebar';
import ViewOnlyWrapper from './ViewOnlyWrapper';

import {
    setActiveCard,
    setIsDataLoading,
    setViewOnlyBriefData,
    setViewOnlyMode,
    setViewOnlyOutlineData,
} from './store/viewOnlySettings.actions';
import { selectViewOnlyActiveCard } from './store/viewOnlySettings.selectors';

import ContentOptimizerContentPage from '../contentPage/ContentOptimizerContentPage';

import {
    optimizerViewOnlyNavMenuOption,
    viewOnlyNavMenuOptions,
    viewOnlyPageLayoutClass,
} from './constants';

const classes = {
    layoutWrapper: 'min-h-100 layout-wrapper',
};

const ContentOptimizerViewOnlyPage = () => {
    const dispatch = useDispatch();
    const { organizationSlug } = useParams();
    const contentOptimizerId = useContext(ContentOptimizerContext);

    const activeCard = useSelector(selectViewOnlyActiveCard);

    const { collapsed, handleCollapse } = useCollapse();

    const fetchOutlineData = () => {
        axiosAPI
            .get(`${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/outlines`, {
                ...getAxiosHeaders(),
                params: {
                    slug: organizationSlug,
                    active: true,
                },
            })
            .then((result) => {
                if (result?.status === statusCodes.success && result?.data?.length !== 0) {
                    dispatch(setViewOnlyOutlineData(result?.data[result?.data?.length - 1]));
                }
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    useEffect(() => {
        if (contentOptimizerId) {
            dispatch(setIsDataLoading(true));

            axiosAPI
                .get(
                    `${contentBriefPathes.getListOfContentBriefs}/${contentOptimizerId}/versions`,
                    {
                        ...getAxiosHeaders(),
                        params: {
                            slug: organizationSlug,
                            active: true,
                        },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success && result?.data?.length !== 0) {
                        dispatch(setViewOnlyBriefData(result?.data[result?.data?.length - 1]));

                        fetchOutlineData();
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    dispatch(setIsDataLoading(false));
                });
        }

        // eslint-disable-next-line
    }, [contentOptimizerId]);

    useEffect(() => {
        dispatch(setViewOnlyMode(true));
        dispatch(setActiveCard(viewOnlyNavMenuOptions.overview));

        return () => {
            dispatch(setViewOnlyMode(false));
        };

        // eslint-disable-next-line
    }, []);

    return (
        <Layout className={classes.layoutWrapper}>
            <ViewOnlySidebar withOptimizer collapsed={collapsed} handleCollapse={handleCollapse} />
            <Layout
                className={viewOnlyPageLayoutClass}
                style={{
                    left: collapsed
                        ? `${elementsStyles.Sider.collapsedWidth}px`
                        : `${elementsStyles.Sider.width}px`,
                    width: collapsed
                        ? `calc(100% - ${elementsStyles.Sider.collapsedWidth}px)`
                        : `calc(100% - ${elementsStyles.Sider.width}px)`,
                }}
            >
                {activeCard === optimizerViewOnlyNavMenuOption?.key ? (
                    <ContentOptimizerContentPage />
                ) : (
                    <ViewOnlyWrapper />
                )}
            </Layout>
        </Layout>
    );
};

export default ContentOptimizerViewOnlyPage;
