import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, ConfigProvider, Popconfirm, Typography } from 'antd';

import { colorPalette } from '../../../../../resources/styles/colorPalette';
import {
    selectDarkMode,
    selectIsSubscriptionExpired,
    selectUserOrganization,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import SubscriptionHelper from '../../../auth/helpers/subscription.helper';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../constants/notificationType';
import { paymentsPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import CancellationModal from '../cancellation-modal/CancellationModal';

import { updateCurrentSubscriptionCancelAtPeriodEnd } from '../store/billingSettings.actions';
import { selectCurrentSubscription } from '../store/billingSettings.selectors';

const classes = {
    cancelSubscriptionWrapper: 'cancel-subscription-wrapper',
    cancelSubscriptionDescription: 'section-description',
    cancelSubscriptionButton: 'btn-danger',
    resumeSubscriptionButton: 'btn-primary',
    popconfirm: 'subscription-delete-popconfirm delete-popconfirm',
    popconfirmDark: 'delete-popconfirm-dark',
};

const CancelSubscriptionSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const darkMode = useSelector(selectDarkMode);
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const subscription = useSelector(selectCurrentSubscription);
    const organization = useSelector(selectUserOrganization);

    const canceledSubscription = SubscriptionHelper.isCanceled(subscription);
    const trialSubscription = SubscriptionHelper.isTrial(
        subscription,
        organization
    );

    const organizationSlug = useOrganizationSlug();

    const [isLoading, setIsLoading] = useState(false);
    const [cancellationModalOpen, setCancellationModalOpen] = useState(false);

    const handleCancelSubscription = () => {
        setIsLoading(true);

        axiosAPI
            .post(
                `${
                    canceledSubscription
                        ? paymentsPathes.resumeSubscription
                        : paymentsPathes.cancelSubscription
                }`,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(
                        updateCurrentSubscriptionCancelAtPeriodEnd(
                            result?.data?.cancel_at_period_end
                        )
                    );
                    openNotification(
                        notificationType.success,
                        'Success',
                        `Subscription successfully ${
                            canceledSubscription ? 'resumed' : 'canceled'
                        }`
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleNavigateToPlans = () => {
        navigate(`/${organizationSlug}/account/plans`);
    };

    const confirmAction = () => {
        !canceledSubscription && !trialSubscription
            ? setCancellationModalOpen(true)
            : handleCancelSubscription();
    };

    return (
        <section className={classes.cancelSubscriptionWrapper}>
            <ConfigProvider
                theme={{
                    token: {
                        colorWarning: canceledSubscription
                            ? colorPalette.colorSuccess
                            : colorPalette.colorError,
                    },
                }}
            >
                {isSubscriptionExpired ? (
                    <Button
                        className={`${classes.resumeSubscriptionButton}
                                            ${darkMode ? 'dark-mode' : ''}`}
                        onClick={handleNavigateToPlans}
                    >
                        Resume subscription
                    </Button>
                ) : (
                    <Popconfirm
                        placement='topLeft'
                        title={`Are you sure you want to ${
                            canceledSubscription ? 'resume' : 'cancel'
                        } your subscription?`}
                        okText='Yes'
                        cancelText='Cancel'
                        onConfirm={confirmAction}
                        okButtonProps={{
                            loading: isLoading,
                        }}
                        overlayClassName={`${classes.popconfirm}
                                           ${
                                               darkMode
                                                   ? classes.popconfirmDark
                                                   : ''
                                           }`}
                        autoAdjustOverflow
                    >
                        <Button
                            className={`${
                                canceledSubscription
                                    ? classes.resumeSubscriptionButton
                                    : classes.cancelSubscriptionButton
                            }
                                ${darkMode ? 'dark-mode' : ''}`}
                            loading={isLoading}
                        >
                            {`${canceledSubscription ? 'Resume' : 'Cancel'}`}{' '}
                            subscription
                        </Button>
                    </Popconfirm>
                )}
            </ConfigProvider>
            <Typography className={classes.cancelSubscriptionDescription}>
                {canceledSubscription
                    ? 'After resuming the subscription, you will have access to all features again and users will regain access to this account.'
                    : 'Canceling your subscription will stop any future billing. After your subscription expires, access to this account will be revoked for all users.'}
            </Typography>
            {cancellationModalOpen && (
                <CancellationModal
                    closeCallback={() => setCancellationModalOpen(false)}
                    submitCallback={handleCancelSubscription}
                />
            )}
        </section>
    );
};

export default CancelSubscriptionSection;
