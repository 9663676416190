export const selectCurrentContentOptimizer = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.currentContentOptimizer;

export const selectCurrentOptimizerLanguageCode = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.currentContentOptimizer
        ?.content_brief?.language_code;

export const selectIsOptimizerLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.isOptimizerLoading;

export const selectBriefIdOfCurrentContentOptimizer = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.currentContentOptimizer
        ?.content_brief_id;

export const selectCurrentContentBriefCompetitor = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.contentBriefCompetitor;

export const selectCurrentCompetitorsList = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsList;

export const selectCompetitorsToUpdate = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsToUpdate;

export const selectCompetitorsPendingRequest = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsPendingRequest;

export const selectIsCompetitorScanUrlLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerContent
        ?.isCompetitorScanUrlLoading;

export const selectCompetitorsSearch = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsFilters
        ?.competitorsSearch;

export const selectContentOptimizerKeywords = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.keywords;

export const selectContentOptimizerSuggestions = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.suggestions;

export const selectContentOptimizerShared = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.shared;

export const selectContentOptimizerSharedLink = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.shareLink;

export const selectCompetitorsSortKey = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsFilters
        ?.competitorsSortKey;

export const selectKeywordsSortKey = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.keywordsSortKey;

export const selectKeywordsSearchKey = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.keywordsSearchKey;

export const selectCompetitorsSortDescending = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.competitorsFilters
        ?.competitorsSortDescending;

export const selectSelectedKeyword = (state) =>
    state?.contentOptimizer?.contentOptimizerContent?.selectedKeyword;

// Multiple keywords selection
export const selectSelectedKeywords = (state) =>
    state.contentOptimizer.contentOptimizerContent.selectedKeywords;

export const selectIsMultipleKeywordsSelectionEnabled = (state) =>
    state.contentOptimizer.contentOptimizerContent
        .isMultipleKeywordsSelectionEnabled;

export const selectIsAnyKeywordSelected = (state) => {
    const selectedKeywords =
        state?.contentOptimizer.contentOptimizerContent.selectedKeywords;

    return selectedKeywords.length > 0;
};
