import moment from 'moment';

import { contentBriefsOutlineActions } from './contentBriefsOutline.actions';
import { addNewVersionToArray } from '../../../../../../../../../utils/helpers/contentOptimizerVersionsHelper';

const contentBriefsOutlineInitialState = {
    currentBrief: null,
    currentBriefLoading: false,
    currentBriefId: null,
    briefVersions: null,
    briefPreviewVersion: null,

    ranksHtags: [],
    isRanksHtagsLoaded: false,

    currentOutline: null,
    currentOutlineLoading: false,
    currentOutlineId: null,
    outlineVersions: null,
    currentOutlineVersion: null,
    outlinePreviewVersion: null,

    briefProgress: null,
    briefTimings: null,
    briefQuestions: null,
    briefCitations: null,

    aiBriefLoading: false,
    outlineVersionLoading: false,
};

export const contentBriefsOutlineReducer = (
    state = contentBriefsOutlineInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentBriefsOutlineActions.SET_AI_BRIEF_LOADING: {
            return {
                ...state,
                aiBriefLoading: payload,
            };
        }

        case contentBriefsOutlineActions.UPDATE_BRIEF_VERSION: {
            const updatedBriefVersions = state?.briefVersions?.map(
                (version) => {
                    if (version?.id !== payload?.id) {
                        return version;
                    }

                    return { ...version, ...payload };
                }
            );

            return {
                ...state,
                briefVersions: updatedBriefVersions,
            };
        }

        case contentBriefsOutlineActions.SET_PREVIEW_BRIEF_VERSION: {
            const previewVersion = {
                ...state?.briefVersions?.find((item) => item?.id === payload),
            };

            return {
                ...state,
                briefPreviewVersion: payload ? previewVersion : null,
                briefProgress: payload
                    ? previewVersion?.progress
                    : state?.currentBrief?.progress,
            };
        }

        case contentBriefsOutlineActions.UPDATE_BRIEF_VERSION_NAME: {
            return {
                ...state,
                briefVersions: state?.briefVersions?.map((item) => {
                    if (item?.id === payload?.id) {
                        return {
                            ...item,
                            name: payload?.name,
                        };
                    }

                    return item;
                }),
            };
        }

        case contentBriefsOutlineActions.DELETE_BRIEF_VERSION: {
            return {
                ...state,
                briefVersions: state?.briefVersions?.filter(
                    (item) => item?.id !== payload
                ),
            };
        }

        case contentBriefsOutlineActions.CLEAR_CONTENT_BRIEFS_SETTINGS: {
            return {
                ...contentBriefsOutlineInitialState,
            };
        }

        case contentBriefsOutlineActions.SET_BRIEF_PROGRESS: {
            return {
                ...state,
                briefProgress: payload,
            };
        }

        case contentBriefsOutlineActions.SET_BRIEF_TIMINGS: {
            return {
                ...state,
                briefTimings: JSON.parse(payload || '[]'),
            };
        }

        case contentBriefsOutlineActions.SET_BRIEF_QUESTIONS: {
            return {
                ...state,
                briefQuestions: JSON.parse(payload || '[]'),
            };
        }

        case contentBriefsOutlineActions.SET_BRIEF_CITATIONS: {
            return {
                ...state,
                briefCitations: JSON.parse(payload || '[]'),
            };
        }

        case contentBriefsOutlineActions.SET_RANKS_HTAGS: {
            return {
                ...state,
                ranksHtags: payload,
                isRanksHtagsLoaded: true,
            };
        }

        case contentBriefsOutlineActions.UPDATE_OUTLINE_VERSIONS: {
            const currentOutline = payload?.filter((item) => item?.active);
            const outlineVersions = payload?.sort(
                (a, b) => moment(b?.created_at) - moment(a?.created_at)
            );

            return {
                ...state,
                currentOutline: currentOutline[0]?.outline
                    ? JSON.parse(currentOutline[0]?.outline)
                    : [],
                currentOutlineId: currentOutline[0]?.id,
                outlineVersions: outlineVersions,
                currentOutlineVersion: currentOutline[0],
                briefProgress: currentOutline[0]?.content_brief?.progress,
                outlinePreviewVersion: null,
            };
        }

        case contentBriefsOutlineActions.ADD_OUTLINE_VERSION: {
            return {
                ...state,
                outlineVersions: addNewVersionToArray(
                    payload,
                    state.outlineVersions
                ),
            };
        }

        case contentBriefsOutlineActions.SET_PREVIEW_OUTLINE_VERSION: {
            const previewVersion = {
                ...state?.outlineVersions?.find((item) => item?.id === payload),
            };

            return {
                ...state,
                outlinePreviewVersion: payload ? previewVersion : null,
                briefProgress: payload
                    ? previewVersion?.progress
                    : state?.currentOutlineVersion?.progress,
            };
        }

        case contentBriefsOutlineActions.UPDATE_OUTLINE_VERSION_NAME: {
            return {
                ...state,
                outlineVersions: state?.outlineVersions?.map((item) => {
                    if (item?.id === payload?.id) {
                        return {
                            ...item,
                            name: payload?.name,
                        };
                    }

                    return item;
                }),
            };
        }

        case contentBriefsOutlineActions.DELETE_OUTLINE_VERSION: {
            return {
                ...state,
                outlineVersions: state?.outlineVersions?.filter(
                    (item) => item?.id !== payload
                ),
            };
        }

        case contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_VERSION_PROGRESS: {
            return {
                ...state,
                outlineVersions: state?.outlineVersions?.map((item) => {
                    if (item?.active) {
                        return {
                            ...item,
                            progress: payload,
                        };
                    } else {
                        return item;
                    }
                }),
                currentOutlineVersion: {
                    ...state.currentOutlineVersion,
                    progress: payload,
                },
            };
        }

        case contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_VERSION_WORD_COUNT: {
            return {
                ...state,
                outlineVersions: state?.outlineVersions?.map((item) => {
                    if (item?.active) {
                        return {
                            ...item,
                            word_count: payload,
                        };
                    } else {
                        return item;
                    }
                }),
                currentOutlineVersion: {
                    ...state.currentOutlineVersion,
                    word_count: payload,
                },
            };
        }

        case contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE_LOADING: {
            return {
                ...state,
                currentOutlineLoading: payload,
            };
        }

        case contentBriefsOutlineActions.UPDATE_CURRENT_OUTLINE: {
            return {
                ...state,
                currentOutline: payload,
            };
        }

        case contentBriefsOutlineActions.APPEND_TO_CURRENT_OUTLINE: {
            const updatedOutline =
                state?.currentOutline?.length > 0
                    ? [...state?.currentOutline, payload]
                    : [payload];

            return {
                ...state,
                currentOutline: updatedOutline,
            };
        }

        case contentBriefsOutlineActions.SET_CURRENT_BRIEF: {
            return {
                ...state,
                currentBrief: payload,
                briefProgress: payload?.content_brief?.progress,
            };
        }

        case contentBriefsOutlineActions.SET_CURRENT_BRIEF_LOADING: {
            return {
                ...state,
                currentBriefLoading: payload,
            };
        }

        case contentBriefsOutlineActions.SET_CURRENT_BRIEF_ID: {
            return {
                ...state,
                currentBriefId: payload,
            };
        }

        case contentBriefsOutlineActions.SET_BRIEF_VERSIONS: {
            const currentBrief = payload?.filter((item) => item?.active);
            const briefVersions = payload?.sort(
                (a, b) => moment(b?.created_at) - moment(a?.created_at)
            );

            return {
                ...state,
                currentBrief: currentBrief[0],
                currentBriefId: currentBrief[0]?.content_brief_id,
                briefVersions: briefVersions,
                briefProgress: currentBrief[0]?.progress,
                briefPreviewVersion: null,
            };
        }

        case contentBriefsOutlineActions.SET_OUTLINE_VERSION_LOADING:
            return {
                ...state,
                outlineVersionLoading: payload,
            };

        default:
            return state;
    }
};
