import { contentMonitorTabs } from '../../../../../constants/contentMonitorMainTabs';
import {
    removeProjectByIds,
    updateProjectNames,
} from '../../../../../utils/helpers/projectHelpers';
import { defaultMonitorDateSortItem } from '../content/header/constants';
import { monitorsSortKeys } from '../content/table/constants';

import { contentMonitorMainActions } from './contentMonitorMain.actions';

const contentMonitorMainInitialState = {
    contentMonitorMainTab: contentMonitorTabs.rankabilityScore.key,
    contentMonitorData: [],
    contentMonitorChartData: [],
    contentMonitorsChecked: [],
    totalCount: 0,
    monitorsCount: {
        active: 0,
        scored: 0,
        archived: 0,
        projects: 0,
    },
    queryParams: {
        search: null,
        dateRange: defaultMonitorDateSortItem,
        project: null,
        users: null,
        dateRangePicker: null,
    },
    isAddURLsActive: false,
    projects: [],

    monitorsTableFilters: {
        competitorsSearch: '',
        monitorsSortKey: monitorsSortKeys.added,
        monitorsSortDescending: false,
    },
};

export const contentMonitorMainReducer = (
    state = contentMonitorMainInitialState,
    { type, payload }
) => {
    switch (type) {
        case contentMonitorMainActions.UPDATE_MONITORS_SORT_KEY: {
            return {
                ...state,
                monitorsTableFilters: {
                    ...state.monitorsTableFilters,
                    monitorsSortKey: payload,
                    monitorsSortDescending:
                        payload === state.monitorsTableFilters.monitorsSortKey
                            ? !state.monitorsTableFilters.monitorsSortDescending
                            : false,
                },
            };
        }

        case contentMonitorMainActions.SET_MONITORS_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        case contentMonitorMainActions.CLEAR_MONITORS_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: null,
            };
        }

        case contentMonitorMainActions.SET_PROJECTS: {
            return {
                ...state,
                projects: payload,
            };
        }

        case contentMonitorMainActions.ADD_PROJECTS: {
            return {
                ...state,
                projects: [payload, ...state.projects],
            };
        }

        case contentMonitorMainActions.SET_IS_ADD_URLS_ACTIVE: {
            return {
                ...state,
                isAddURLsActive: payload,
            };
        }

        case contentMonitorMainActions.UPDATE_CONTENT_MONITOR_MAIN_TAB: {
            return {
                ...state,
                contentMonitorMainTab: payload,
            };
        }

        case contentMonitorMainActions.UPDATE_CONTENT_MONITOR_DATA: {
            if (Array.isArray(payload)) {
                return {
                    ...state,
                    contentMonitorData: state.contentMonitorData?.map((monitor) => {
                        const updatedMonitor = payload?.find(
                            (updatedMonitor) => updatedMonitor?.id === monitor?.id
                        );

                        return updatedMonitor
                            ? {
                                  ...monitor,
                                  ...updatedMonitor,
                              }
                            : monitor;
                    }),
                };
            } else {
                return {
                    ...state,
                    contentMonitorData: state.contentMonitorData?.map((monitor) => {
                        return payload?.id === monitor?.id
                            ? {
                                  ...monitor,
                                  ...payload,
                              }
                            : monitor;
                    }),
                };
            }
        }

        case contentMonitorMainActions.UPDATE_CONTENT_MONITOR_PROJECTS: {
            if (!payload?.length) return { ...state };

            return {
                ...state,
                projects: updateProjectNames(
                    state.projects,
                    payload,
                    contentMonitorMainInitialState.projects
                ),
                contentMonitorData:
                    state.contentMonitorData?.map((item) => {
                        return {
                            ...item,
                            cm_projects: updateProjectNames(item.cm_projects, payload),
                        };
                    }) || contentMonitorMainInitialState.contentMonitorData,
            };
        }

        case contentMonitorMainActions.REMOVE_CONTENT_MONITOR_PROJECT_IDS: {
            if (!payload?.length || typeof payload !== 'object') return { ...state };

            return {
                ...state,
                projects: removeProjectByIds(
                    state.projects,
                    payload,
                    contentMonitorMainInitialState.projects
                ),
                contentMonitorData:
                    state.contentMonitorData?.map((item) => {
                        return {
                            ...item,
                            cm_projects: removeProjectByIds(item.cm_projects, payload),
                        };
                    }) || contentMonitorMainInitialState.contentMonitorData,
            };
        }

        case contentMonitorMainActions.REMOVE_CONTENT_MONITOR_DATA: {
            let filteredResult = state.contentMonitorData.filter(
                (item) => !payload.deleted.includes(item.id)
            );

            if (payload.enabled.length) {
                filteredResult = filteredResult.map((x) =>
                    payload.enabled.includes(x.id)
                        ? { ...x, disabled: false }
                        : x
                );
            }

            return {
                ...state,
                contentMonitorData: filteredResult,
            };
        }

        case contentMonitorMainActions.ADD_CONTENT_MONITOR_DATA: {
            const oldState = state.contentMonitorData || [];

            return {
                ...state,
                contentMonitorData: [...payload?.reverse(), ...oldState],
            };
        }

        case contentMonitorMainActions.SET_CONTENT_MONITOR_DATA: {
            return {
                ...state,
                contentMonitorData: payload,
            };
        }

        case contentMonitorMainActions.SET_CONTENT_MONITORS_CHECKED: {
            return {
                ...state,
                contentMonitorsChecked: payload,
            };
        }

        case contentMonitorMainActions.CLEAR_CONTENT_MONITOR_MAIN: {
            return {
                ...contentMonitorMainInitialState,
            };
        }

        case contentMonitorMainActions.SET_CONTENT_MONITOR_CHART_DATA: {
            return {
                ...state,
                contentMonitorChartData: payload,
            };
        }

        case contentMonitorMainActions.UPDATE_CONTENT_MONITOR_CHART_DATA: {
            const idsToUpdate = payload.map((item) => item.id);
            const existedChartData = (
                state.contentMonitorChartData || []
            ).filter(
                (chartData) =>
                    !idsToUpdate.includes(chartData.id) &&
                    chartData.rankability_scores
            );

            const dataToUpdate =
                payload
                    ?.filter(
                        (updatedChartData) =>
                            updatedChartData.rankability_scores
                    )
                    .map((updatedMonitor) => {
                        const { id, rankability_scores } = updatedMonitor;

                        return {
                            id,
                            rankability_scores,
                        };
                    }) || [];

            return {
                ...state,
                contentMonitorChartData: existedChartData.concat(dataToUpdate),
            };
        }

        case contentMonitorMainActions.REMOVE_CONTENT_MONITOR_CHART_DATA: {
            let filteredResult = state.contentMonitorChartData?.filter(
                (chartItem) => !payload.deleted.includes(chartItem.id)
            );

            if (payload.enabled.length) {
                filteredResult = filteredResult.map((chartItem) =>
                    payload.enabled.includes(chartItem.id)
                );
            }

            return {
                ...state,
                contentMonitorChartData: filteredResult || [],
            };
        }

        default: {
            return state;
        }
    }
};
