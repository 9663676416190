export const unauthorizedRoutes = {
    login: '/login',
    signUp: '/signup',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password/:token',
    confirmInstructions: '/confirm-instructions',
    confirmEmail: '/confirm/:token',
    selectPlan: '/select-plan',
    viewOnlyPage: '/:organizationSlug/share/optimizer/:shareLinkUrlCode',
    azureHealthcheck: '/azure-healthcheck',
};
