import React from 'react';

import { Checkbox } from 'antd';

import EditAndDeleteButtons from '../buttons/EditAndDeleteButtons';

const classes = {
    editDeleteWrapperButtons: 'd-flex',
};

const CustomCheckboxList = ({
    itemList = [],
    checkedByDefault = false,
    itemKeyName = 'available',
    itemWrapperClass = 'edit-delete-project-buttons',
    handleSelectItem = () => {},
    handleEditName = () => {},
    handleDeleteById = () => {},
}) => {
    return (
        <>
            {itemList.map((item) => (
                <div
                    className={`${itemWrapperClass} ${classes.editDeleteWrapperButtons}`}
                    key={`${itemKeyName}_wrapper_${item.id}`}
                >
                    <Checkbox
                        id={`${itemKeyName}${item.id}`}
                        key={`${itemKeyName}${item.id}`}
                        value={item.id}
                        onChange={(event) => handleSelectItem(event)}
                        checked={checkedByDefault}
                        defaultChecked={checkedByDefault}
                    />
                    <EditAndDeleteButtons
                        key={`${itemKeyName}_project_button${item.id}`}
                        value={item.name}
                        onConfirmEdit={(value) => {
                            return handleEditName(item.id, value);
                        }}
                        onConfirmDelete={() => handleDeleteById(item.id)}
                    />
                </div>
            ))}
        </>
    );
};

export default CustomCheckboxList;
