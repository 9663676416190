import { viewOnlyPageLayoutClass } from '../../components/pages/contentOptimizer/viewOnly/constants';

export const executeScroll = (ref, toStartPosition = false) => {
    if (ref?.current) {
        const parent = document.getElementsByClassName(viewOnlyPageLayoutClass)?.[0];

        parent?.style.setProperty('scroll-padding-top', toStartPosition ? '84px' : '0');
        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
};
