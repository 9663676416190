export const optimizerViewOnlyNavMenuOption = {
    key: 'contentOptimizer',
    label: 'Content Optimizer',
};

export const viewOnlyNavMenuOptions = {
    overview: 'overview',
    seo: 'seo',
    links: 'links',
    similarContent: 'similar-content',
    contentOutline: 'content-outline',
    contentOptimizer: 'contentOptimizer',
};

export const viewOnlyPageLayoutClass = 'site-layout';
