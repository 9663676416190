import React from 'react';

const RankabilityRoundedLightLogoIcon = ({ width = '40' }) => {
    return (
        <svg width={width} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1700 1700'>
            <defs>
                <style>
                    {`.aRankabilityRoundedLightLogoIcon {
                        fill: #327eef;
                    }

                    .bRankabilityRoundedLightLogoIcon {
                      fill: #000e4c;
                    }`}
                </style>
            </defs>
            <path
                className='aRankabilityRoundedLightLogoIcon'
                d='M969.79,1039.37c24.22,1.86,47.74,7.13,66.55,20.84,22.6,16.48,28.29,46.36,24,72.58-4.68,28.32-18.62,51.94-47.61,59.5-13.92,3.63-28.48,4.23-42.79,4.23h-400c-17,0-34.74-2.62-50.41-9.44-32-13.89-45.52-47.06-39.73-80.43,3.47-20,10.42-41.54,27.57-53.94,20.83-15.08,52.12-13.73,76.58-13.73h40.79V692.39h-6.16c-24,0-49.26,2.48-72.25-5.89-27.22-9.91-38.66-34.86-41.2-62.29-2.37-25.56,1.37-51.68,21.66-69.49q22.6-19.85,61-19.86H746.61c13.92,0,27.18,4.41,34.84,16.7,19.32,31,8.22,70.82,5.57,104.53-.47,5.95-.68,11.2-.68,15.76q6.82-6.84,14.38-13.7t15.75-13.7A783.59,783.59,0,0,1,935,554.72c73.15-45.44,206.33-67.16,268.5,8.91q21.22,26,26.71,54.79a59.74,59.74,0,0,0,.69,8.9,66.13,66.13,0,0,1,.68,8.91v7.53a24.38,24.38,0,0,1-1.37,7.54,84.43,84.43,0,0,1-13,33.56q-10.28,15.76-28.09,26.71l-5.47,2.74-5.49,2.74a213.5,213.5,0,0,1-23.28,8.91,69,69,0,0,1-20.55,3.42,93.79,93.79,0,0,1-37-7.53q-17.82-7.51-35.62-17.12a6.6,6.6,0,0,1-3.42-1.38,21.29,21.29,0,0,1-3.43-2.73q-13.71-6.84-27.39-12.34a103.36,103.36,0,0,0-28.77-6.85h-5.48c-3.66,0-7.77.24-12.33.69a89.84,89.84,0,0,0-12.33,2.06q-30.15,8.22-58.9,28.76a639.1,639.1,0,0,0-54.8,43.84q-15.09,13.71-28.08,26.71l-24,24V1039h98C922,1039,946.2,1037.56,969.79,1039.37Z'
                transform='translate(-5 -5)'
            />
            <path
                className='bRankabilityRoundedLightLogoIcon'
                d='M855,225a626.14,626.14,0,0,1,352.09,107.46,634.79,634.79,0,0,1,170.45,170.45,628.94,628.94,0,0,1,0,704.18,634.79,634.79,0,0,1-170.45,170.45,628.94,628.94,0,0,1-704.18,0,634.79,634.79,0,0,1-170.45-170.45,628.94,628.94,0,0,1,0-704.18A634.79,634.79,0,0,1,502.91,332.46,626.14,626.14,0,0,1,855,225M855,5C385.56,5,5,385.56,5,855s380.56,850,850,850,850-380.56,850-850S1324.44,5,855,5Z'
                transform='translate(-5 -5)'
            />
        </svg>
    );
};

export default RankabilityRoundedLightLogoIcon;
