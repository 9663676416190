export const SPELLING_DIFF_CLASS = 'spelling-diff';

export const spellingGrammarCongratsMessages = [
    {
        title: 'Excellent job!',
        message: 'All of the elements appear to be correctly spelled and grammatically accurate.',
    },
    {
        title: 'Awesome work!',
        message: 'All aspects appear to be grammatically accurate and spelled correctly.',
    },
    {
        title: 'Nicely done!',
        message: 'Everything seems perfectly spelled and grammatically accurate.',
    },
    {
        title: 'Superb job!',
        message: 'All of the elements appear to be grammatically correct and properly spelled.',
    },
    {
        title: 'Great work!',
        message: 'Everything looks properly spelled and grammatically correct.',
    },
    {
        title: 'Well done!',
        message: 'Everything is grammatically accurate and correctly spelled.',
    },
    {
        title: 'Wonderful work!',
        message: 'Everything appears to be spelled correctly and grammatically accurate.',
    },
    {
        title: 'Congrats!',
        message: 'Everything is spelled accurately and grammatically sound.',
    },
    {
        title: 'Fantastic job!',
        message: 'Your spelling and grammar are on point.',
    },
    {
        title: 'Outstanding work!',
        message: 'Every element is correctly spelled and grammatically accurate.',
    },
    {
        title: 'Excellent effort!',
        message: 'All components are correctly spelled and grammatically flawless.',
    },
    {
        title: 'Incredible job!',
        message: 'Everything is spelled accurately and grammatically correct.',
    },
    {
        title: 'Terrific work!',
        message: 'Your spelling and grammar are perfect throughout.',
    },
    {
        title: 'Congratulations!',
        message: 'The spelling and grammar are flawless throughout.',
    },
    {
        title: 'Boom!',
        message: 'Your content appears to be correctly spelled and grammatically accurate.',
    },
    {
        title: 'Impressive job!',
        message: 'All the elements are grammatically sound and spelled correctly.',
    },
    {
        title: 'Stellar job!',
        message: 'Everything is spelled and punctuated correctly, with perfect grammar.',
    },
    {
        title: 'Amazing work!',
        message: 'The spelling and grammar are precise and accurate.',
    },
    {
        title: 'Exceptional effort!',
        message: 'Your grammar and spelling are impeccable.',
    },
    {
        title: 'Flawless work!',
        message: 'Every detail is grammatically accurate and spelled correctly.',
    },
    {
        title: 'Fantastic effort!',
        message: 'The spelling and grammar are flawless.',
    },
    {
        title: 'Superb work!',
        message: 'All aspects are grammatically accurate and perfectly spelled.',
    },
    {
        title: 'Excellent writing!',
        message: 'Your spelling and grammar are impeccable.',
    },
    {
        title: 'Magnificent job!',
        message: 'Everything is grammatically sound and correctly spelled.',
    },
    {
        title: 'Nailed it!',
        message: 'Your content’s spelling, grammar, and punctuation are spot on.',
    },
];

export const improveWritingCongratsMessages = [
    {
        title: 'Congratulations!',
        message: 'No writing improvements are necessary.',
    },
];
