import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Fireworks } from 'fireworks-js';

import { calculateOptimalRankabilityScore } from '../../../../../utils/helpers/getOptimizerBasicData';
import { usePrevious } from '../../../../../utils/hooks/usePrevious';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentEditorVersion } from './optimizerTab/editor/store/contentOptimizerEditor.selectors';

const explosions = [
    { launches: 2, delay: 0 },
    { launches: 2, delay: 400 },
    { launches: 1, delay: 800 },
    { launches: 1, delay: 1100 },
    { launches: 1, delay: 1700 },
    { launches: 2, delay: 2500 },
    { launches: 2, delay: 3250 },
    { launches: 4, delay: 3750 },
    { launches: 5, delay: 3900 },
    { launches: 5, delay: 4250 },
];

const FireworksContainer = () => {
    const fireworksRef = useRef(null);

    const currentOptimizerVersion = useSelector(selectCurrentEditorVersion);
    const darkMode = useSelector(selectDarkMode);

    const prevRankabilityScore = usePrevious(currentOptimizerVersion?.rankability_score);

    const optimalRankabilityScore = calculateOptimalRankabilityScore(
        currentOptimizerVersion?.optimal_rankability_score?.max
    );

    const [showFireworks, setShowFireworks] = useState(false);

    useEffect(() => {
        if (
            currentOptimizerVersion &&
            currentOptimizerVersion?.rankability_score >= optimalRankabilityScore &&
            (prevRankabilityScore || prevRankabilityScore === 0) &&
            prevRankabilityScore < optimalRankabilityScore &&
            !showFireworks
        ) {
            setShowFireworks(true);
        }

        // eslint-disable-next-line
    }, [currentOptimizerVersion]);

    useEffect(() => {
        if (showFireworks && fireworksRef.current) {
            const fireworks = new Fireworks(fireworksRef.current, {
                rocketsPoint: { min: 20, max: 80 },
                hue: { min: darkMode ? 220 : 224, max: darkMode ? 220 : 224 },
                acceleration: 1.0,
                brightness: { min: darkMode ? 35 : 20, max: darkMode ? 40 : 25 },
                decay: { min: 0.01, max: 0.025 },
                delay: { min: 10, max: 25 },
                explosion: 6,
                particles: 120,
                traceLength: 1.0,
                flickering: 50,
                traceSpeed: 100,
                gravity: 2.15,
                lineWidth: {
                    explosion: { min: 0.75, max: 1.15 },
                    trace: { min: 0.01, max: 0.01 },
                },
            });

            const timeoutIds = [];

            explosions.forEach((explosion) => {
                const timeoutId = setTimeout(
                    () => fireworks.launch(explosion.launches),
                    explosion.delay
                );

                timeoutIds.push(timeoutId);
            });

            const clearTimeoutId = setTimeout(() => setShowFireworks(false), 7000);

            timeoutIds.push(clearTimeoutId);

            return () => {
                timeoutIds.forEach(clearTimeout);
                fireworks.clear();
            };
        }
    }, [showFireworks]);

    if (!showFireworks) {
        return null;
    }

    return <div ref={fireworksRef} id='fireworks-container' />;
};

export default FireworksContainer;
