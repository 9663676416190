import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { unauthorizedRoutes } from './routes';

import ContentOptimizerProvider from '../../../hocs/ContentOptimizerProvider';

import LoginPageWrapper from '../../../pages/auth/LoginPageWrapper';
import ContentOptimizerViewOnlyPage from '../../../pages/contentOptimizer/viewOnly/ContentOptimizerViewOnlyPage';
import ForbiddenPage from '../../../pages/error/ForbiddenPage';
import NotFoundPage from '../../../pages/error/NotFoundPage';
import ErrorsHandeledLayout from '../../layout/ErrorsHandledLayout';
import ResetPassword from '../../../pages/auth/reset-password/ResetPassword';
import UnauthorizedLayout from '../../layout/Unauthorized/UnauthorizedLayout';
import ForgotPassword from '../../../pages/auth/content/ForgotPassword';
import ConfirmInstructions from '../../../pages/auth/confirm-instructions/ConfirmInstructions';
import ConfirmedEmail from '../../../pages/auth/confirmed-email/ConfirmedEmail';
import SignUp from '../../../pages/auth/content/signUp/SignUp';
import SelectPlanWrapper from '../../../pages/auth/select-plan/SelectPlanWrapper';
import AzureHealthcheck from '../../../pages/azureHealthcheck/AzureHealthcheck';

const UnauthorizedRouter = () => {
	return (
		<Routes>
			<Route element={<UnauthorizedLayout />}>
				<Route path={unauthorizedRoutes.login} element={<LoginPageWrapper />} />
				<Route path={unauthorizedRoutes.signUp} element={<SignUp />} />
				<Route
					path={unauthorizedRoutes.forgotPassword}
					element={<ForgotPassword />}
				/>
				<Route
					path={unauthorizedRoutes.resetPassword}
					element={<ResetPassword />}
				/>
				<Route
					path={unauthorizedRoutes.confirmInstructions}
					element={<ConfirmInstructions />}
				/>
				<Route
					path={unauthorizedRoutes.confirmEmail}
					element={<ConfirmedEmail />}
				/>
				<Route
					path={unauthorizedRoutes.selectPlan}
					element={<SelectPlanWrapper />}
				/>
				<Route
					path={unauthorizedRoutes.azureHealthcheck}
					element={<AzureHealthcheck />}
				/>

				<Route element={<ErrorsHandeledLayout />}>
					<Route
						path={unauthorizedRoutes.viewOnlyPage}
						element={
							<ContentOptimizerProvider>
								<ContentOptimizerViewOnlyPage />
							</ContentOptimizerProvider>
						}
					/>
				</Route>

				<Route path={`error/403`} element={<ForbiddenPage />} />
				<Route path={`error/404`} element={<NotFoundPage />} />
			</Route>
			<Route path='*' element={<Navigate to={unauthorizedRoutes.login} />} />
		</Routes>
	);
};

export default UnauthorizedRouter;
