import React from 'react';
import { useSelector } from 'react-redux';

import { selectContentOptimizerViewOnlyMode } from '../../../../../viewOnly/store/viewOnlySettings.selectors';

import AddCustomKeywordModal from './AddCustomKeywordModal';
// import OptimizerMultipleSelectionTool from './OptimizerMultipleSelectionTool';
import OptimizerKeywordsCopyButton from './OptimizerKeywordsCopyButton';
import OptimizerKeywordsExportButton from './OptimizerKeywordsExportButton';
// import OptimizerKeywordsResetToDefaultButton from './OptimizerKeywordsResetToDefaultButton';
import OptimizerKeywordsSortDropdown from './OptimizerKeywordsSortDropdown';
import OptimizerSearchInput from './OptimizerSearchInput';

const classes = {
    topSectionWrapper: 'd-flex align-items-center w-100 keywords-top-section-wrapper',
};

const OptimizerKeywordsTopSection = () => {
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);

    return (
        <div className={classes.topSectionWrapper}>
            <OptimizerKeywordsSortDropdown />
            <div className='d-flex align-items-center'>
                {!viewOnlyMode && <AddCustomKeywordModal />}
                <OptimizerKeywordsCopyButton />
                {/* NOTE: Uncomment when it's needed */}
                {/*{!viewOnlyMode && <OptimizerKeywordsResetToDefaultButton />}*/}
                <OptimizerKeywordsExportButton />
                {/* <OptimizerMultipleSelectionTool /> */}
            </div>
            <div className='mt-1 w-100'>
                <OptimizerSearchInput />
            </div>
        </div>
    );
};

export default OptimizerKeywordsTopSection;
