import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomHeader from '../../../../common/header/Header';

import { updateUsageQueryParams } from '../store/usageSettings.actions';
import { selectUsageSettingsQueryParams } from '../store/usageSettings.selectors';

import {
    defaultUsageDateSortItem,
    defaultUsageUserSortItem,
    usageDateSortItems,
    usageUserSortItems,
} from './usagePageSortOptions';

const UsagePageHeader = () => {
    const dispatch = useDispatch();

    const queryParams = useSelector(selectUsageSettingsQueryParams);
    const searchQuery = queryParams.search;

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (!searchQuery) {
            setSearchValue('');
        }
    }, [searchQuery]);

    useEffect(() => {
        const updateUsageParams = setTimeout(() => {
            dispatch(updateUsageQueryParams({ search: searchValue.trim().replace(/\s+/g, ' ') }));
        }, 1500);

        return () => clearTimeout(updateUsageParams);

        // eslint-disable-next-line
    }, [searchValue]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleDateSort = (value) => {
        dispatch(updateUsageQueryParams({ dateRange: value }));
    };

    const handleUserSort = (value) => {
        dispatch(updateUsageQueryParams({ userSort: value }));
    };

    return (
        <CustomHeader
            showSearch
            handleSearch={handleSearch}
            defaultSearchValue={searchQuery}
            searchValue={searchValue}
            showSort
            sortConfigs={[
                {
                    sortItems: usageDateSortItems,
                    sortDropdownPrefix: 'Graph display',
                    selectedSort: queryParams?.dateRange || defaultUsageDateSortItem,
                    handleSort: handleDateSort,
                },
                // TODO: uncomment after expanding functionality and info about
                //  stripe subscriptions to our database
                // {
                //     sortItems: usageUserSortItems,
                //     sortDropdownPrefix: 'Sort by',
                //     selectedSort: queryParams?.userSort || defaultUsageUserSortItem,
                //     handleSort: handleUserSort,
                // },
            ]}
        />
    );
};

export default UsagePageHeader;
