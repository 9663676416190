import { sortOrders } from '../../../../../constants/sortOrders';
import { organizationsSortFields } from '../../constants';
import { adminOrganizationsSettingsActions } from './adminOrganizationsSettings.actions';

const adminOrganizationsSettingsInitialState = {
    organizationsData: [],
    isDataLoading: false,
    queryParams: {
        page: 1,
        per_page: 20,
        sort_field: organizationsSortFields.created_at,
        sort_order: sortOrders.desc,
    },
    totalPages: 1,
};

export const adminOrganizationsSettingsReducer = (
    state = adminOrganizationsSettingsInitialState,
    { type, payload }
) => {
    switch (type) {
        case adminOrganizationsSettingsActions.CLEAR_ADMIN_ORGANIZATIONS_SETTINGS: {
            return {
                ...adminOrganizationsSettingsInitialState,
            };
        }

        case adminOrganizationsSettingsActions.SET_ORGANIZATIONS_DATA: {
            return {
                ...state,
                organizationsData: payload,
            };
        }

        case adminOrganizationsSettingsActions.UPDATE_ORGANIZATION_SETTINGS: {
            const updatedItems = state.organizationsData.map((item) => {
                if (item.id === payload.id) {
                    return {
                        ...item,
                        settings: { ...item.settings, ...payload.settings },
                    }
                }

                return item;
            });

            return {
                ...state,
                organizationsData: updatedItems,
            };
        }

        case adminOrganizationsSettingsActions.UPDATE_ORGANIZATION_USER: {
            const updatedItems = state.organizationsData.map((item) => ({
                ...item,
                users: item.users.map((user) =>
                    user.id === payload?.id ? { ...user, ...payload } : user
                ),
            }));

            return {
                ...state,
                organizationsData: updatedItems,
            };
        }

        case adminOrganizationsSettingsActions.SET_ORGANIZATIONS_IS_DATA_LOADING: {
            return {
                ...state,
                isDataLoading: payload,
            };
        }

        case adminOrganizationsSettingsActions.UPDATE_ORGANIZATION_QUERY_PARAMS: {
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...payload,
                },
            };
        }

        case adminOrganizationsSettingsActions.SET_ORGANIZATIONS_TOTAL_PAGES: {
            return {
                ...state,
                totalPages: payload,
            };
        }

        default:
            return state;
    }
};
