export const contentMonitorMainActions = {
    CLEAR_CONTENT_MONITOR_MAIN: 'CONTENT_MONITOR_MAIN/CLEAR_CONTENT_MONITOR_MAIN',

    UPDATE_CONTENT_MONITOR_MAIN_TAB: 'CONTENT_MONITOR_MAIN/UPDATE_CONTENT_MONITOR_MAIN_TAB',

    SET_CONTENT_MONITOR_DATA: 'CONTENT_MONITOR_MAIN/SET_CONTENT_MONITOR_DATA',
    ADD_CONTENT_MONITOR_DATA: 'CONTENT_MONITOR_MAIN/ADD_CONTENT_MONITOR_DATA',
    UPDATE_CONTENT_MONITOR_DATA: 'CONTENT_MONITOR_MAIN/UPDATE_CONTENT_MONITOR_DATA',
    REMOVE_CONTENT_MONITOR_DATA: 'CONTENT_MONITOR_MAIN/REMOVE_CONTENT_MONITOR_DATA',
    UPDATE_CONTENT_MONITOR_PROJECTS: 'CONTENT_MONITOR_MAIN/UPDATE_CONTENT_MONITOR_PROJECTS',
    REMOVE_CONTENT_MONITOR_PROJECT_IDS: 'CONTENT_MONITOR_MAIN/REMOVE_CONTENT_MONITOR_PROJECT_IDS',

    SET_CONTENT_MONITORS_CHECKED: 'CONTENT_MONITOR_MAIN/SET_CONTENT_MONITORS_CHECKED',
    SET_IS_ADD_URLS_ACTIVE: 'CONTENT_MONITOR_MAIN/SET_IS_ADD_URLS_ACTIVE',

    SET_PROJECTS: 'CONTENT_MONITOR_MAIN/SET_PROJECTS',
    ADD_PROJECTS: 'CONTENT_MONITOR_MAIN/ADD_PROJECTS',

    SET_MONITORS_QUERY_PARAMS: 'CONTENT_MONITOR_MAIN/SET_MONITORS_QUERY_PARAMS',
    CLEAR_MONITORS_QUERY_PARAMS: 'CONTENT_MONITOR_MAIN/CLEAR_MONITORS_QUERY_PARAMS',

    UPDATE_MONITORS_SORT_KEY: 'CONTENT_MONITOR_MAIN/UPDATE_MONITORS_SORT_KEY',

    SET_CONTENT_MONITOR_CHART_DATA: 'CONTENT_MONITOR_MAIN/SET_CONTENT_MONITOR_CHART_DATA',
    UPDATE_CONTENT_MONITOR_CHART_DATA: 'CONTENT_MONITOR_MAIN/UPDATE_CONTENT_MONITOR_CHART_DATA',
    REMOVE_CONTENT_MONITOR_CHART_DATA: 'CONTENT_MONITOR_MAIN/REMOVE_CONTENT_MONITOR_CHART_DATA',
};

export const updateMonitorsSortKey = (payload) => {
    return {
        type: contentMonitorMainActions.UPDATE_MONITORS_SORT_KEY,
        payload,
    };
};

export const clearMonitorsQueryParams = () => {
    return {
        type: contentMonitorMainActions.SET_MONITORS_QUERY_PARAMS,
    };
};

export const setMonitorsQueryParams = (payload) => {
    return {
        type: contentMonitorMainActions.SET_MONITORS_QUERY_PARAMS,
        payload,
    };
};

export const setContentMonitorProject = (payload) => {
    return {
        type: contentMonitorMainActions.SET_PROJECTS,
        payload,
    };
};

export const addContentMonitorProject = (payload) => {
    return {
        type: contentMonitorMainActions.ADD_PROJECTS,
        payload,
    };
};

export const setIsAddURLsActive = (payload) => {
    return {
        type: contentMonitorMainActions.SET_IS_ADD_URLS_ACTIVE,
        payload,
    };
};

export const updateContentMonitorMainTab = (payload) => {
    return {
        type: contentMonitorMainActions.UPDATE_CONTENT_MONITOR_MAIN_TAB,
        payload,
    };
};

export const removeContentMonitorData = (payload) => {
    return {
        type: contentMonitorMainActions.REMOVE_CONTENT_MONITOR_DATA,
        payload,
    };
};

export const addContentMonitorData = (payload) => {
    return {
        type: contentMonitorMainActions.ADD_CONTENT_MONITOR_DATA,
        payload,
    };
};

export const setContentMonitorData = (payload) => {
    return {
        type: contentMonitorMainActions.SET_CONTENT_MONITOR_DATA,
        payload,
    };
};

export const updateContentMonitorData = (payload) => {
    return {
        type: contentMonitorMainActions.UPDATE_CONTENT_MONITOR_DATA,
        payload,
    };
};

export const updateContentMonitorProjects = (payload = []) => {
    return {
        type: contentMonitorMainActions.UPDATE_CONTENT_MONITOR_PROJECTS,
        payload,
    };
};

export const removeContentMonitorProjectIds = (payload = []) => {
    return {
        type: contentMonitorMainActions.REMOVE_CONTENT_MONITOR_PROJECT_IDS,
        payload,
    };
};

export const setContentMonitorChecked = (payload) => {
    return {
        type: contentMonitorMainActions.SET_CONTENT_MONITORS_CHECKED,
        payload,
    };
};

export const clearContentMonitorMain = () => {
    return {
        type: contentMonitorMainActions.CLEAR_CONTENT_MONITOR_MAIN,
    };
};

export const setContentMonitorChartData = (payload) => {
    return {
        type: contentMonitorMainActions.SET_CONTENT_MONITOR_CHART_DATA,
        payload,
    };
};

export const updateContentMonitorChartData = (payload = []) => {
    return {
        type: contentMonitorMainActions.UPDATE_CONTENT_MONITOR_CHART_DATA,
        payload,
    };
};

export const removeContentMonitorChartData = (payload) => {
    return {
        type: contentMonitorMainActions.REMOVE_CONTENT_MONITOR_CHART_DATA,
        payload: {
            deleted: payload?.deleted || [],
            enabled: payload?.enabled || [],
        },
    };
};
