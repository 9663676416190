import React, { useContext } from 'react';

import { useCurrentEditor } from '@tiptap/react';

import { contentOptimizerPathes } from '../../../../../../constants/queryPathes';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../../../utils/helpers/openBasicErrorNotification';
import { getRandomId } from '../../../../../../utils/helpers/idGenerator';

import { ContentOptimizerContext } from '../../../../../hocs/ContentOptimizerProvider';

const SelectImageInput = ({ inputFile }) => {
    const contentOptimizerId =  useContext(ContentOptimizerContext);
    const { editor } = useCurrentEditor();

    const saveImageInStorage = (imageData) => {
        axiosAPI
            .post(
                contentOptimizerPathes.imageStorage,
                {
                    content_optimizer_id: contentOptimizerId,
                    image_data: imageData,
                    image_file_name: getRandomId() + '.png',
                },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                editor.chain().focus().setImage({ src: result.data.url }).run();
            })
            .catch(() => {
                openBasicErrorNotification();
            });
    };

    return (
        <input
            type='file'
            name='myImage'
            accept='image/*'
            style={{ display: 'none' }}
            ref={inputFile}
            onChange={(event) => {
                const selectedFile = event.target.files;

                if (selectedFile && selectedFile[0]?.type?.includes('image')) {
                    const [imageFile] = selectedFile;
                    const fileReader = new FileReader();

                    fileReader.onload = () => {
                        const srcData = fileReader.result;

                        saveImageInStorage(srcData);
                    };

                    fileReader.readAsDataURL(imageFile);
                }

                event.target.value = null;
            }}
        />
    );
};

export default SelectImageInput;
