import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, InputNumber, Modal, Typography } from 'antd';

import CloseIcon from '../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { parseErrorMessages } from '../../../../../utils/helpers/parseErrorMessages';

import { quotaEditFields } from '../../constants';
import { techAdminPathes } from '../../../../../constants/queryPathes';
import { subscriptionLimits } from '../../../../../constants/subscriptionLimits';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

import { updateQuota } from '../store/adminQuotasSettings.actions';

const { Title } = Typography;

const classes = {
    modalWrapper: 'edit-quota-modal',
    modalWrapperDark: 'edit-quota-modal-dark dark-input-autofill',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalSubtitle: 'modal-subtitle',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
};

const EditQuotaModal = ({
    editableQuota,
    quotaItem = subscriptionLimits.optimizers,
    isOpen,
    handleOpen,
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {
        setError(null);
        setIsLoading(false);
        form.resetFields();
    };

    const handleAddCredits = (values) => {
        const newValue = { [quotaItem]: editableQuota[quotaItem] + values[quotaItem] || 0 };

        setIsLoading(true);

        axiosAPI
            .patch(
                `${techAdminPathes.editQuotas}/${editableQuota.id}`,
                { ...newValue },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(updateQuota({ id: editableQuota.id, ...newValue }));
                    handleOpen();
                }
            })
            .catch((error) => {
                setError(
                    parseErrorMessages(error?.response) ||
                        'Something went wrong. Please try again later.'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Modal
                centered
                title={`Add ${quotaEditFields[quotaItem]}`}
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper} ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form form={form} layout='vertical' onFinish={handleAddCredits}>
                    <div className={classes.modalFormFieldsWrapper}>
                        <Title level={5} className={classes.modalSubtitle}>
                            {editableQuota.organization?.name}
                        </Title>
                        {error && <ErrorMessage errorMessage={error} />}
                        <Form.Item
                            key={quotaItem}
                            name={quotaItem}
                            label={quotaEditFields[quotaItem]}
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter a number for ${quotaEditFields[quotaItem]} to add`,
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                max={Number.MAX_SAFE_INTEGER}
                                precision={0}
                                placeholder={`Enter ${quotaEditFields[quotaItem].toLowerCase()} count to add`}
                            />
                        </Form.Item>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button type='primary' size='large' ghost onClick={handleOpen}>
                            Cancel
                        </Button>
                        <Button type='primary' size='large' htmlType='submit' loading={isLoading}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default EditQuotaModal;
