import React, { useEffect, useState } from 'react';

import { Button, Tooltip } from 'antd';

import { useCurrentEditor } from '@tiptap/react';
import { useSelector } from 'react-redux';

import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectAIStateResponse } from '../../store/tiptapEditor.selectors';

import {
    getThemeColorClass,
    getTooltipOverlayClass,
} from '../../../../../utils/helpers/themeHelpers';
import { useIconColor } from '../../../../../utils/hooks/useIconColor';

import { commandsWithDiffs } from '../../constants/aiEditorConstants';
import { nodeTypes } from '../../constants/nodeTypes';
import { SPELLING_DIFF_CLASS } from '../../constants/spellingDiffsConstants';

const classes = {
    navigationWrapper: 'd-flex align-items-center',
    navigateButton: 'navigate-button btn-hover-light-primary',
    activeCorrection: 'active-correction',
};

const AICorrectionsNavigation = () => {
    const { editor } = useCurrentEditor();
    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);
    const response = useSelector(selectAIStateResponse);

    const themeColorClass = getThemeColorClass(darkMode);
    const tooltipOverlayClass = getTooltipOverlayClass(darkMode);

    const activeAiCommand = editor.storage[nodeTypes.aiSpellChecker].activeAiCommand;
    const spellingErrorsDetected = editor.storage[nodeTypes.aiSpellChecker].spellingErrorsDetected;

    const [correctionIndex, setCorrectionIndex] = useState(-1);
    const [corrections, setCorrections] = useState([]);

    useEffect(() => {
        if (spellingErrorsDetected) {
            // Wait for the next frame to render all the corrections
            requestAnimationFrame(() => {
                const correctionsList = document.querySelectorAll(`.${SPELLING_DIFF_CLASS}`);

                setCorrections(Array.from(correctionsList));
                setCorrectionIndex(-1);
            });
        }
    }, [spellingErrorsDetected, editor, response]);

    const scrollToCorrection = (index) => {
        if (corrections.length > 0 && corrections[index]) {
            corrections[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            highlightCorrection(corrections[index]);
        }
    };

    const highlightCorrection = (element) => {
        corrections.forEach((correction) => correction.classList.remove(classes.activeCorrection));
        element.classList.add(classes.activeCorrection);
    };

    const handlePreviousCorrection = () => {
        const newIndex = correctionIndex > 0 ? correctionIndex - 1 : corrections.length - 1;

        setCorrectionIndex(newIndex);
        scrollToCorrection(newIndex);
    };

    const handleNextCorrection = () => {
        const newIndex = correctionIndex < corrections.length - 1 ? correctionIndex + 1 : 0;

        setCorrectionIndex(newIndex);
        scrollToCorrection(newIndex);
    };

    if (!commandsWithDiffs.includes(activeAiCommand) || !spellingErrorsDetected) {
        return null;
    }

    return (
        <div className={classes.navigationWrapper}>
            <Tooltip title='Previous correction' overlayClassName={tooltipOverlayClass}>
                <Button
                    className={`${classes.navigateButton} ${themeColorClass}`}
                    icon={<SiderChevronUpIcon color={iconColor} />}
                    onClick={handlePreviousCorrection}
                />
            </Tooltip>
            <Tooltip title='Next correction' overlayClassName={tooltipOverlayClass}>
                <Button
                    className={`${classes.navigateButton} ${themeColorClass}`}
                    icon={<SiderChevronUpIcon color={iconColor} />}
                    onClick={handleNextCorrection}
                />
            </Tooltip>
        </div>
    );
};

export default AICorrectionsNavigation;
