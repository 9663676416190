import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    keywordResearchMainSortItems,
    keywordResearchMainSortOptions,
} from '../../../../constants/keywordResearchMainSortOptions';

import CustomHeader from '../../../common/header/Header';

import { updateKeywordQueryParam } from '../store/keywordResearchSettings.actions';
import {
    selectKeywordQueryParams,
    selectKeywordQuerySearch,
} from '../store/keywordResearchSettings.selectors';

const KeywordResearchPageHeader = () => {
    const dispatch = useDispatch();

    const searchQuery = useSelector(selectKeywordQuerySearch);
    const queryParams = useSelector(selectKeywordQueryParams);

    const [searchValue, setSearchValue] = useState('');
    const [selectedSort, setSelectedSort] = useState(keywordResearchMainSortOptions.recent.key);

    useEffect(() => {
        if (!searchQuery) {
            setSearchValue('');
        }
    }, [searchQuery]);

    useEffect(() => {
        const updateKeywordParam = setTimeout(() => {
            dispatch(
                updateKeywordQueryParam({
                    search: searchValue.trim(),
                    page: 1,
                })
            );
        }, 1500);

        return () => clearTimeout(updateKeywordParam);

        // eslint-disable-next-line
    }, [searchValue]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    useEffect(() => {
        const selectedSortKey = Object.keys(keywordResearchMainSortOptions).find(
            (key) =>
                keywordResearchMainSortOptions[key].queryParams.sort === queryParams.sort &&
                keywordResearchMainSortOptions[key].queryParams.order === queryParams.order
        );

        setSelectedSort(selectedSortKey);
    }, [queryParams]);

    const handleSort = (value) => {
        setSelectedSort(value);
        dispatch(
            updateKeywordQueryParam({
                ...keywordResearchMainSortOptions[value].queryParams,
                page: 1,
            })
        );
    };

    return (
        <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            showSearch
            handleSearch={handleSearch}
            defaultSearchValue={searchQuery}
            searchValue={searchValue}
            showSort
            sortItems={keywordResearchMainSortItems}
            selectedSort={selectedSort}
            handleSort={handleSort}
            showViewMode
        />
    );
};

export default KeywordResearchPageHeader;
