import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectViewOnlyBriefData } from '../../../store/viewOnlySettings.selectors';

import { getThemeColorClass } from '../../../../../../../utils/helpers/themeHelpers';

import './CardsContentStyles.scss';

const classes = {
    root: 'seo-card-content',
    propertyValue: 'property-value',
    propertyName: 'property-name text-decoration-dotted',
    linksValue: 'links-value ',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const seoCardContentKeys = [
    {
        title: 'Page URL',
        description: 'Page URL',
        dataKey: 'page_url',
    },
    {
        title: 'Meta Title (Title Tag)',
        description: 'Meta Title (Title Tag)',
        dataKey: 'meta_title',
    },
    {
        title: 'Meta Description',
        description: 'Meta Description',
        dataKey: 'meta_description',
    },
    {
        title: 'Page Title (H1 Tag)',
        description: 'Page Title (H1 Tag)',
        dataKey: 'page_title',
    },
    {
        title: 'Word Count',
        description: 'Word Count',
        dataKey: 'word_count',
    },
    {
        title: 'Primary Keyword',
        description: 'Primary Keyword',
        dataKey: 'primary_keyword',
    },
    {
        title: 'Top NLP Keywords',
        description: 'Top NLP Keywords',
        dataKey: 'secondary_keywords',
    },
];

const SeoCardContent = () => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyBriefData = useSelector(selectViewOnlyBriefData);

    return (
        <div className={classes.root}>
            {seoCardContentKeys?.map((seoItem, index) => (
                <React.Fragment key={index}>
                    <Tooltip
                        overlayClassName={
                            `${classes.tooltipOverlay} ${darkMode && classes.tooltipOverlayDark}`
                        }
                        title={seoItem?.description}
                        placement='top'
                    >
                        <span
                            className={`${classes.propertyName} ${getThemeColorClass(darkMode)}`}
                        >
                            {seoItem?.title}
                        </span>
                    </Tooltip>

                    <Typography
                        className={`${classes.propertyValue} ${getThemeColorClass(darkMode)}`}
                    >
                        {viewOnlyBriefData?.extras[seoItem?.dataKey] || 'N/A'}
                    </Typography>
                </React.Fragment>
            ))}
        </div>
    );
};

export default SeoCardContent;
