import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout, Spin, Typography, theme } from 'antd';

import moment from 'moment';

import { dateFormat } from '../../../../constants/dateConsts';
import { globalAdminPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectUsageSettingsQueryParams } from './store/usageSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openBasicErrorNotification } from '../../../../utils/helpers/openBasicErrorNotification';
import { usePrevious } from '../../../../utils/hooks/usePrevious';

import Loader from '../../../common/loader/Loader';
import UsageChartsSection from './content/UsageChartsSection';
import UsageOrganizationStatsTable from './content/UsageOrganizationStatsTable';
import UsagePageHeader from './content/UsagePageHeader';

import { dateSortKeys } from '../../contentMonitor/mainPage/content/header/constants';

import { usageActions } from './content/usagePageActions';
import { defaultUsageDateSortItem } from './content/usagePageSortOptions';

import './UsagePage.scss';

const { Content } = Layout;
const { Title } = Typography;

const classes = {
    pageLayoutWrapper: 'page-layout-wrapper',
    organizationTitleWrapper: 'page-title-wrapper',
    pageTitle: 'page-title',
    content: 'container usage-page-content',
    contentDark: 'container-dark',
    usageContentWrapper: 'usage-content-wrapper',
};

const calculateStats = (data) => {
    const aiCreditsActions = new Set(usageActions.ai_credits_actions);

    return data?.reduce((accumulator, item) => {
        const { user, organization, action } = item;
        const userId = user?.id;
        let record = accumulator.find((record) => record.userId === userId);

        if (!record) {
            record = {
                userId,
                user: `${user?.first_name} ${user?.last_name}`,
                organization: organization?.name,
                organizationId: organization?.id,
                keywordResearches: 0,
                contentBriefs: 0,
                contentMonitors: 0,
                aiCredits: 0,
            };
            accumulator.push(record);
        }

        switch (action) {
            case usageActions.create_keyword_research:
                record.keywordResearches += 1;
                break;
            case usageActions.create_content_brief:
                record.contentBriefs += 1;
                break;
            case usageActions.create_content_monitor:
                record.contentMonitors += 1;
                break;
            default:
                if (aiCreditsActions.has(action)) {
                    record.aiCredits += 1;
                }
        }

        return accumulator;
    }, []);
};

const getStartDateAndPeriodLength = (periodKey) => {
    const firstMonthDay = moment().startOf('month');
    const today = moment().startOf('day');

    let startDate = moment(today).subtract(30, 'days').startOf('day');
    let endDate = moment().endOf('day');
    let periodLength = 30;

    switch (periodKey) {
        case dateSortKeys.thisMonth:
            periodLength = endDate.diff(firstMonthDay, 'days') + 1;
            break;

        case dateSortKeys.lastMonth:
            startDate = moment(firstMonthDay).subtract(1, 'month').startOf('month');
            endDate = moment(firstMonthDay).subtract(1, 'month').endOf('month');
            periodLength = endDate.diff(startDate, 'days') + 1;
            break;

        case dateSortKeys.last30Days:
            break;

        case dateSortKeys.last90Days:
            periodLength = 90;
            break;

        default:
            break;
    }

    return { endDate, periodLength };
};

const mapAdminUsageDataToDisplay = (data, actions, period) => {
    const { endDate, periodLength } = getStartDateAndPeriodLength(period);

    const initialValue = Array.from({ length: periodLength }, (_, i) => {
        return {
            name: moment(endDate).subtract(i, 'days').format(dateFormat.shortMonthDayWithZeroYear),
            value: 0,
        };
    });

    const descUsageData =
        data?.reduce((accumulator, item) => {
            if (actions.includes(item?.action)) {
                const date = moment
                    .utc(item.created_at)
                    .local()
                    .format(dateFormat.shortMonthDayWithZeroYear);
                const index = accumulator?.findIndex((x) => x.name === date);

                if (index !== -1) {
                    accumulator[index].value += 1;
                } else {
                    accumulator.push({ name: date, value: 1 });
                }
            }

            return accumulator;
        }, initialValue) || [];

    return [...descUsageData].reverse();
};

const UsagePage = () => {
    const darkMode = useSelector(selectDarkMode);
    const queryParams = useSelector(selectUsageSettingsQueryParams);

    const prevQueryParams = usePrevious(queryParams);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [usageData, setUsageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (JSON.stringify(prevQueryParams) !== JSON.stringify(queryParams)) {
            setIsLoading(true);

            axiosAPI
                .get(
                    globalAdminPathes.usage,
                    {
                        params: queryParams,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        const stats = calculateStats(result.data);

                        setUsageData({
                            contentOptimizerCount: mapAdminUsageDataToDisplay(
                                result?.data,
                                [usageActions.create_content_brief],
                                queryParams?.dateRange || defaultUsageDateSortItem
                            ),
                            contentMonitorCount: mapAdminUsageDataToDisplay(
                                result?.data,
                                [usageActions.create_content_monitor],
                                queryParams?.dateRange || defaultUsageDateSortItem
                            ),
                            keywordResearchCount: mapAdminUsageDataToDisplay(
                                result?.data,
                                [usageActions.create_keyword_research],
                                queryParams?.dateRange || defaultUsageDateSortItem
                            ),
                            aiCreditCount: mapAdminUsageDataToDisplay(
                                result?.data,
                                usageActions.ai_credits_actions,
                                queryParams?.dateRange || defaultUsageDateSortItem
                            ),
                            stats,
                        });
                    }
                })
                .catch(() => {
                    openBasicErrorNotification();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        // eslint-disable-next-line
    }, [queryParams]);

    return (
        <>
            <UsagePageHeader />
            <div className={classes.pageLayoutWrapper}>
                <div className={classes.organizationTitleWrapper}>
                    <Title level={2} className={classes.pageTitle}>
                        Usage
                    </Title>
                </div>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content} ${darkMode ? classes.contentDark : ''}`}
                >
                    <div className={classes.usageContentWrapper}>
                        <Spin spinning={isLoading} indicator={<Loader />}>
                            <UsageChartsSection
                                contentOptimizers={usageData?.contentOptimizerCount}
                                contentMonitors={usageData?.contentMonitorCount}
                                keywordResearches={usageData?.keywordResearchCount}
                                aiCredits={usageData?.aiCreditCount}
                            />
                            <UsageOrganizationStatsTable organizationStats={usageData?.stats} />
                        </Spin>
                    </div>
                </Content>
            </div>
        </>
    );
};

export default UsagePage;
