import React from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Form, Tooltip } from 'antd';

import {
    selectDarkMode,
    selectOrganizationAllowExportSetting,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const ExportCheckbox = ({ value = false, onChange = () => null }) => {
    const darkMode = useSelector(selectDarkMode);
    const organizationAllowExportSetting =
        useSelector(selectOrganizationAllowExportSetting) || false;

    return organizationAllowExportSetting ? (
        <Form.Item name='export_nlp' valuePropName='checked' hidden>
            <Tooltip
                title='Enable NLP Export Setting - managed by a Global Administrator'
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
            >
                <Checkbox
                    key='export_nlp'
                    checked={value}
                    onChange={onChange}
                    defaultChecked={false}
                >
                    Enable NLP Export
                </Checkbox>
            </Tooltip>
        </Form.Item>
    ) : null;
};

export default ExportCheckbox;
