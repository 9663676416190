import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'antd';

import { contentOptimizerMainTabs } from '../../../../../constants/contentOptimizerMainTabs';

import CreateMultipleSection from '../../../../common/createNew/CreateMultipleSection';
import CreateSingleSection from '../../../../common/createNew/CreateSingleSection';
import ContentOptimizersList from '../../common/ContentOptimizersList';

import {
    addContentOptimizerData,
    clearContentOptimizerMainQueryParam,
    updateContentOptimizerMainQueryParam,
} from '../store/contentOptimizerMain.actions';
import {
    selectContentOptimizerMainQueryParams,
    selectContentOptimizerMainTab,
    selectContentOptimizerMainTotalCount,
} from '../store/contentOptimizerMain.selectors';

const { Title } = Typography;

const classes = {
    listHeaderTitle: 'list-header-title',
};

const ContentOptimizerCreateNewContent = ({ isOptimizerLoading }) => {
    const dispatch = useDispatch();

    const selectedTab = useSelector(selectContentOptimizerMainTab);

    const { page, per_page: pageSize } = useSelector(
        selectContentOptimizerMainQueryParams
    );
    const total = useSelector(selectContentOptimizerMainTotalCount);

    const handleChangePage = (page) => {
        dispatch(updateContentOptimizerMainQueryParam({ page: page }));
    };

    const handleCreateSingle = (optimizerModel) => {
        dispatch(addContentOptimizerData(optimizerModel));
        dispatch(clearContentOptimizerMainQueryParam());
    };

    const handleCreateMultiple = (optimizerModel) => {
        dispatch(addContentOptimizerData(optimizerModel));
        dispatch(clearContentOptimizerMainQueryParam());
    };

    return (
        <>
            {selectedTab === contentOptimizerMainTabs.single.key ? (
                <CreateSingleSection
                    getOptimizers
                    runBtnText='Run Optimizer'
                    handleCreateSingle={handleCreateSingle}
                />
            ) : (
                <CreateMultipleSection
                    getOptimizers
                    runBtnText='Run Optimizer'
                    handleCreateMultiple={handleCreateMultiple}
                />
            )}
            <ContentOptimizersList
                isOptimizerLoading={isOptimizerLoading}
                listTitle={
                    <Title level={3} className={classes.listHeaderTitle}>
                        Recent Optimizers
                    </Title>
                }
                page={page}
                pageSize={pageSize}
                total={total}
                handleChangePage={handleChangePage}
                showOnlyDeleteAction={true}
            />
        </>
    );
};

export default ContentOptimizerCreateNewContent;
